import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // thisVue : {},
    token: null,
    language: null,
    formdata: null,
    aff_id: null,
    aff_cid: null,
    countries: [
      {
        code: "AF",
        country: "Afghanistan"
      },
      {
        code: "AX",
        country: "Aland Islands"
      },
      {
        code: "AL",
        country: "Albania"
      },
      {
        code: "DZ",
        country: "Algeria"
      },
      {
        code: "AS",
        country: "American Samoa"
      },
      {
        code: "AD",
        country: "Andorra"
      },
      {
        code: "AO",
        country: "Angola"
      },
      {
        code: "AI",
        country: "Anguilla"
      },
      {
        code: "AQ",
        country: "Antarctica"
      },
      {
        code: "AG",
        country: "Antigua and Barbuda"
      },
      {
        code: "AR",
        country: "Argentina"
      },
      {
        code: "AM",
        country: "Armenia"
      },
      {
        code: "AW",
        country: "Aruba"
      },
      {
        code: "AU",
        country: "Australia"
      },
      {
        code: "AT",
        country: "Austria"
      },
      {
        code: "AZ",
        country: "Azerbaijan"
      },
      {
        code: "BS",
        country: "Bahamas"
      },
      {
        code: "BH",
        country: "Bahrain"
      },
      {
        code: "BD",
        country: "Bangladesh"
      },
      {
        code: "BB",
        country: "Barbados"
      },
      {
        code: "BY",
        country: "Belarus"
      },
      {
        code: "BE",
        country: "Belgium"
      },
      {
        code: "BZ",
        country: "Belize"
      },
      {
        code: "BJ",
        country: "Benin"
      },
      {
        code: "BM",
        country: "Bermuda"
      },
      {
        code: "BT",
        country: "Bhutan"
      },
      {
        code: "BO",
        country: "Bolivia"
      },
      {
        code: "BQ",
        country: "Bonaire"
      },
      {
        code: "BA",
        country: "Bosnia and Herzegovina"
      },
      {
        code: "BW",
        country: "Botswana"
      },
      {
        code: "BV",
        country: "Bouvet Island"
      },
      {
        code: "BR",
        country: "Brazil"
      },
      {
        code: "IO",
        country: "British Indian Ocean Territory"
      },
      {
        code: "BN",
        country: "Brunei"
      },
      {
        code: "BG",
        country: "Bulgaria"
      },
      {
        code: "BF",
        country: "Burkina Faso"
      },
      {
        code: "BU",
        country: "Burma"
      },
      {
        code: "BI",
        country: "Burundi"
      },
      {
        code: "KH",
        country: "Cambodia"
      },
      {
        code: "CM",
        country: "Cameroon"
      },
      {
        code: "CA",
        country: "Canada"
      },
      {
        code: "CT",
        country: "Canton and Enderbury Islands"
      },
      {
        code: "CV",
        country: "Cape Verde"
      },
      {
        code: "KY",
        country: "Cayman Islands"
      },
      {
        code: "CF",
        country: "Central African Republic"
      },
      {
        code: "TD",
        country: "Chad"
      },
      {
        code: "CL",
        country: "Chile"
      },
      {
        code: "CN",
        country: "China"
      },
      {
        code: "CX",
        country: "Christmas Island"
      },
      {
        code: "CC",
        country: "Cocos — KEELING Islands"
      },
      {
        code: "CO",
        country: "Colombia"
      },
      {
        code: "KM",
        country: "Comoros"
      },
      {
        code: "CD",
        country: "Congo"
      },
      {
        code: "CG",
        country: "Congo"
      },
      {
        code: "CK",
        country: "Cook Islands"
      },
      {
        code: "CR",
        country: "Costa Rica"
      },
      {
        code: "CI",
        country: "Cote D'ivoire"
      },
      {
        code: "HR",
        country: "Croatia"
      },
      {
        code: "CU",
        country: "Cuba"
      },
      {
        code: "CW",
        country: "Curacao"
      },
      {
        code: "CY",
        country: "Cyprus"
      },
      {
        code: "CZ",
        country: "Czech Republic"
      },
      {
        code: "YD",
        country: "Democratic Yemen"
      },
      {
        code: "DK",
        country: "Denmark"
      },
      {
        code: "DJ",
        country: "Djibouti"
      },
      {
        code: "DM",
        country: "Dominica"
      },
      {
        code: "DO",
        country: "Dominican Republic"
      },
      {
        code: "NQ",
        country: "Dronning Maud Land"
      },
      {
        code: "TP",
        country: "East Timor"
      },
      {
        code: "EC",
        country: "Ecuador"
      },
      {
        code: "EG",
        country: "Egypt"
      },
      {
        code: "SV",
        country: "El Salvador"
      },
      {
        code: "GQ",
        country: "Equatorial Guinea"
      },
      {
        code: "ER",
        country: "Eritrea"
      },
      {
        code: "EE",
        country: "Estonia"
      },
      {
        code: "ET",
        country: "Ethiopia"
      },
      {
        code: "FK",
        country: "Falkland Islands"
      },
      {
        code: "FO",
        country: "Faroe Islands"
      },
      {
        code: "FJ",
        country: "Fiji"
      },
      {
        code: "FI",
        country: "Finland"
      },
      {
        code: "FR",
        country: "France"
      },
      {
        code: "FX",
        country: "France Metropolitan"
      },
      {
        code: "GF",
        country: "French Guiana"
      },
      {
        code: "PF",
        country: "French Polynesia"
      },
      {
        code: "TF",
        country: "French Southern Territories"
      },
      {
        code: "GA",
        country: "Gabon"
      },
      {
        code: "GM",
        country: "Gambia"
      },
      {
        code: "GE",
        country: "Georgia"
      },
      {
        code: "DE",
        country: "Germany"
      },
      {
        code: "GH",
        country: "Ghana"
      },
      {
        code: "GI",
        country: "Gibraltar"
      },
      {
        code: "GR",
        country: "Greece"
      },
      {
        code: "GL",
        country: "Greenland"
      },
      {
        code: "GD",
        country: "Grenada"
      },
      {
        code: "GP",
        country: "Guadeloupe"
      },
      {
        code: "GU",
        country: "Guam"
      },
      {
        code: "GT",
        country: "Guatemala"
      },
      {
        code: "GG",
        country: "Guernsey"
      },
      {
        code: "GN",
        country: "Guinea"
      },
      {
        code: "GW",
        country: "Guinea-bissau"
      },
      {
        code: "GY",
        country: "Guyana"
      },
      {
        code: "HT",
        country: "Haiti"
      },
      {
        code: "HM",
        country: "Heard and Mc Donald Islands"
      },
      {
        code: "HN",
        country: "Honduras"
      },
      {
        code: "HK",
        country: "Hong Kong"
      },
      {
        code: "HU",
        country: "Hungary"
      },
      {
        code: "IS",
        country: "Iceland"
      },
      {
        code: "IN",
        country: "India"
      },
      {
        code: "ID",
        country: "Indonesia"
      },
      {
        code: "IR",
        country: "Iran"
      },
      {
        code: "IQ",
        country: "Iraq"
      },
      {
        code: "IE",
        country: "Ireland"
      },
      {
        code: "IM",
        country: "Isle Of Man"
      },
      {
        code: "IL",
        country: "Israel"
      },
      {
        code: "IT",
        country: "Italy"
      },
      {
        code: "JM",
        country: "Jamaica"
      },
      {
        code: "JP",
        country: "Japan"
      },
      {
        code: "JE",
        country: "Jersey"
      },
      {
        code: "JT",
        country: "Johnston Island"
      },
      {
        code: "JO",
        country: "Jordan"
      },
      {
        code: "KZ",
        country: "Kazakhstan"
      },
      {
        code: "KE",
        country: "Kenya"
      },
      {
        code: "KI",
        country: "Kiribati"
      },
      {
        code: "KV",
        country: "Kosovo"
      },
      {
        code: "KW",
        country: "Kuwait"
      },
      {
        code: "KG",
        country: "Kyrgyzstan"
      },
      {
        code: "LA",
        country: "Laos"
      },
      {
        code: "LV",
        country: "Latvia"
      },
      {
        code: "LB",
        country: "Lebanon"
      },
      {
        code: "LS",
        country: "Lesotho"
      },
      {
        code: "LR",
        country: "Liberia"
      },
      {
        code: "LY",
        country: "Libya"
      },
      {
        code: "LI",
        country: "Liechtenstein"
      },
      {
        code: "LT",
        country: "Lithuania"
      },
      {
        code: "LU",
        country: "Luxembourg"
      },
      {
        code: "MO",
        country: "Macau"
      },
      {
        code: "MK",
        country: "Macedonia"
      },
      {
        code: "MG",
        country: "Madagascar"
      },
      {
        code: "MW",
        country: "Malawi"
      },
      {
        code: "MY",
        country: "Malaysia"
      },
      {
        code: "MV",
        country: "Maldives"
      },
      {
        code: "ML",
        country: "Mali"
      },
      {
        code: "MT",
        country: "Malta"
      },
      {
        code: "MH",
        country: "Marshall Islands"
      },
      {
        code: "MQ",
        country: "Martinique"
      },
      {
        code: "MR",
        country: "Mauritania"
      },
      {
        code: "MU",
        country: "Mauritius"
      },
      {
        code: "YT",
        country: "Mayotte"
      },
      {
        code: "MX",
        country: "Mexico"
      },
      {
        code: "FM",
        country: "Micronesia - Federated States Of"
      },
      {
        code: "MI",
        country: "Midway Islands"
      },
      {
        code: "MD",
        country: "Moldova"
      },
      {
        code: "MC",
        country: "Monaco"
      },
      {
        code: "MN",
        country: "Mongolia"
      },
      {
        code: "ME",
        country: "Montenegro"
      },
      {
        code: "MS",
        country: "Montserrat"
      },
      {
        code: "MA",
        country: "Morocco"
      },
      {
        code: "MZ",
        country: "Mozambique"
      },
      {
        code: "MM",
        country: "Myanmar"
      },
      {
        code: "NA",
        country: "Namibia"
      },
      {
        code: "NR",
        country: "Nauru"
      },
      {
        code: "NP",
        country: "Nepal"
      },
      {
        code: "NL",
        country: "Netherlands"
      },
      {
        code: "AN",
        country: "Netherlands Antilles"
      },
      {
        code: "NT",
        country: "Neutral Zone"
      },
      {
        code: "NC",
        country: "New Caledonia"
      },
      {
        code: "NZ",
        country: "New Zealand"
      },
      {
        code: "NI",
        country: "Nicaragua"
      },
      {
        code: "NE",
        country: "Niger"
      },
      {
        code: "NG",
        country: "Nigeria"
      },
      {
        code: "NU",
        country: "Niue"
      },
      {
        code: "NF",
        country: "Norfolk Island"
      },
      {
        code: "KP",
        country: "North Korea"
      },
      {
        code: "MP",
        country: "Northern Mariana Islands"
      },
      {
        code: "NO",
        country: "Norway"
      },
      {
        code: "OM",
        country: "Oman"
      },
      {
        code: "PK",
        country: "Pakistan"
      },
      {
        code: "PW",
        country: "Palau"
      },
      {
        code: "PS",
        country: "Palestinian Territories"
      },
      {
        code: "PA",
        country: "Panama"
      },
      {
        code: "PG",
        country: "Papua New Guinea"
      },
      {
        code: "PY",
        country: "Paraguay"
      },
      {
        code: "PE",
        country: "Peru"
      },
      {
        code: "PH",
        country: "Philippines"
      },
      {
        code: "PN",
        country: "Pitcairn Islands"
      },
      {
        code: "PL",
        country: "Poland"
      },
      {
        code: "PT",
        country: "Portugal"
      },
      {
        code: "PR",
        country: "Puerto Rico"
      },
      {
        code: "QA",
        country: "Qatar"
      },
      {
        code: "RE",
        country: "Reunion"
      },
      {
        code: "RO",
        country: "Romania"
      },
      {
        code: "RU",
        country: "Russia"
      },
      {
        code: "RW",
        country: "Rwanda"
      },
      {
        code: "BL",
        country: "Saint Barthelemy"
      },
      {
        code: "KN",
        country: "Saint Kitts and Nevis"
      },
      {
        code: "LC",
        country: "Saint Lucia"
      },
      {
        code: "MF",
        country: "Saint Martin — French Part"
      },
      {
        code: "VC",
        country: "Saint Vincent and The Grenadines"
      },
      {
        code: "WS",
        country: "Samoa"
      },
      {
        code: "SM",
        country: "San Marino"
      },
      {
        code: "ST",
        country: "Sao Tome and Principe"
      },
      {
        code: "SA",
        country: "Saudi Arabia"
      },
      {
        code: "SN",
        country: "Senegal"
      },
      {
        code: "RS",
        country: "Serbia"
      },
      {
        code: "CS",
        country: "Serbia and Montenegro"
      },
      {
        code: "SC",
        country: "Seychelles"
      },
      {
        code: "SL",
        country: "Sierra Leone"
      },
      {
        code: "SG",
        country: "Singapore"
      },
      {
        code: "SX",
        country: "Saint Martin — Dutch Part"
      },
      {
        code: "SK",
        country: "Slovakia"
      },
      {
        code: "SI",
        country: "Slovenia"
      },
      {
        code: "SB",
        country: "Solomon Islands"
      },
      {
        code: "SO",
        country: "Somalia"
      },
      {
        code: "ZA",
        country: "South Africa"
      },
      {
        code: "GS",
        country: "South Georgia and The South Sandwich Islands"
      },
      {
        code: "KR",
        country: "South Korea"
      },
      {
        code: "SS",
        country: "South Sudan"
      },
      {
        code: "ES",
        country: "Spain"
      },
      {
        code: "LK",
        country: "Sri Lanka"
      },
      {
        code: "SH",
        country: "St. Helena"
      },
      {
        code: "PM",
        country: "St. Pierre and Miquelon"
      },
      {
        code: "SD",
        country: "Sudan"
      },
      {
        code: "SR",
        country: "Suriname"
      },
      {
        code: "SJ",
        country: "Svalbard and Jan Mayen Islands"
      },
      {
        code: "SZ",
        country: "Swaziland"
      },
      {
        code: "SE",
        country: "Sweden"
      },
      {
        code: "CH",
        country: "Switzerland"
      },
      {
        code: "SY",
        country: "Syria"
      },
      {
        code: "TW",
        country: "Taiwan"
      },
      {
        code: "TJ",
        country: "Tajikistan"
      },
      {
        code: "TZ",
        country: "Tanzania"
      },
      {
        code: "TH",
        country: "Thailand"
      },
      {
        code: "TL",
        country: "Timor-leste"
      },
      {
        code: "TG",
        country: "Togo"
      },
      {
        code: "TK",
        country: "Tokelau"
      },
      {
        code: "TO",
        country: "Tonga"
      },
      {
        code: "TT",
        country: "Trinidad and Tobago"
      },
      {
        code: "TN",
        country: "Tunisia"
      },
      {
        code: "TR",
        country: "Turkey"
      },
      {
        code: "TM",
        country: "Turkmenistan"
      },
      {
        code: "TC",
        country: "Turks and Caicos Islands"
      },
      {
        code: "TV",
        country: "Tuvalu"
      },
      {
        code: "UG",
        country: "Uganda"
      },
      {
        code: "UA",
        country: "Ukraine"
      },
      {
        code: "AE",
        country: "United Arab Emirates"
      },
      {
        code: "GB",
        country: "United Kingdom"
      },
      {
        code: "US",
        country: "United States"
      },
      {
        code: "UM",
        country: "United States Minor Outlying Islands"
      },
      {
        code: "UY",
        country: "Uruguay"
      },
      {
        code: "UZ",
        country: "Uzbekistan"
      },
      {
        code: "VU",
        country: "Vanuatu"
      },
      {
        code: "VA",
        country: "Vatican City State — HOLY See"
      },
      {
        code: "VE",
        country: "Venezuela"
      },
      {
        code: "VN",
        country: "Vietnam"
      },
      {
        code: "VG",
        country: "Virgin Islands — British"
      },
      {
        code: "VI",
        country: "Virgin Islands — U.S."
      },
      {
        code: "WK",
        country: "Wake Island"
      },
      {
        code: "WF",
        country: "Wallis and Futuna Islands"
      },
      {
        code: "EH",
        country: "Western Sahara"
      },
      {
        code: "YE",
        country: "Yemen"
      },
      {
        code: "ZR",
        country: "Zaire"
      },
      {
        code: "ZM",
        country: "Zambia"
      },
      {
        code: "ZW",
        country: "Zimbabwe"
      }
    ],
    citizenship_countries: [
      { code: "AD", country: "Andorra" },
      { code: "AU", country: "Australia" },
      { code: "AT", country: "Austria" },
      { code: "BE", country: "Belgium" },
      { code: "BN", country: "Brunei" },
      { code: "CL", country: "Chile" },
      { code: "HR", country: "Croatia" },
      { code: "CZ", country: "Czech Republic" },
      { code: "DK", country: "Denmark" },
      { code: "EE", country: "Estonia" },
      { code: "FI", country: "Finland" },
      { code: "FR", country: "France" },
      { code: "DE", country: "Germany" },
      { code: "GR", country: "Greece" },
      { code: "HU", country: "Hungary" },
      { code: "IS", country: "Iceland" },
      { code: "IE", country: "Ireland" },
      { code: "IT", country: "Italy" },
      { code: "JP", country: "Japan" },
      { code: "LV", country: "Latvia" },
      { code: "LI", country: "Liechtenstein" },
      { code: "LT", country: "Lithuania" },
      { code: "LU", country: "Luxembourg" },
      { code: "MT", country: "Malta" },
      { code: "MC", country: "Monaco" },
      { code: "NL", country: "Netherlands" },
      { code: "NZ", country: "New Zealand" },
      { code: "NO", country: "Norway" },
      { code: "PT", country: "Portugal" },
      { code: "PL", country: "Poland" },
      { code: "SM", country: "San Marino" },
      { code: "SG", country: "Singapore" },
      { code: "SK", country: "Slovakia" },
      { code: "SI", country: "Slovenia" },
      { code: "KR", country: "South Korea" },
      { code: "ES", country: "Spain" },
      { code: "SE", country: "Sweden" },
      { code: "CH", country: "Switzerland" },
      { code: "TW", country: "Taiwan" },
      { code: "GB", country: "United Kingdom" },
    ],
    citizenship_to_national_id: ["BE","BN","CL","HR","CZ","EE","DE","GR","HU","LU","MT","MC","NL","PT","PL","SG","SK","SI","KR","ES"],
    citizenship_to_personal_id: ["TW"],
    country_to_phone: {
      "AF": "93:AF",
      "AL": "355:AL",
      "DZ": "213:DZ",
      "AS": "1:AS",
      "AD": "376:AD",
      "AO": "244:AO",
      "AI": "1:AI",
      "AQ": "672:AQ",
      "AG": "1:AG",
      "AR": "54:AR",
      "AM": "374:AM",
      "AW": "297:AW",
      "AC": "247:AC",
      "AU": "61:AU",
      "AT": "43:AT",
      "AZ": "994:AZ",
      "BS": "1:BS",
      "BH": "973:BH",
      "BD": "880:BD",
      "BB": "1:BB",
      "BY": "375:BY",
      "BE": "32:BE",
      "BZ": "501:BZ",
      "BJ": "229:BJ",
      "BM": "1:BM",
      "BT": "975:BT",
      "BO": "591:BO",
      "BA": "387:BA",
      "BW": "267:BW",
      "BR": "55:BR",
      "VG": "1:VG",
      "BN": "673:BN",
      "BG": "359:BG",
      "BF": "226:BF",
      "MM": "95:MM",
      "BI": "257:BI",
      "KH": "855:KH",
      "CM": "237:CM",
      "CA": "1:CA",
      "CV": "238:CV",
      "KY": "1:KY",
      "CF": "236:CF",
      "TD": "235:TD",
      "CL": "56:CL",
      "CN": "86:CN",
      "CX": "61:CX",
      "CC": "61:CC",
      "CO": "57:CO",
      "KM": "269:KM",
      "CG": "242:CG",
      "CK": "682:CK",
      "CR": "506:CR",
      "HR": "385:HR",
      "CU": "53:CU",
      "CY": "357:CY",
      "CZ": "420:CZ",
      "CD": "243:CD",
      "DK": "45:DK",
      "DG": "246:DG",
      "DJ": "253:DJ",
      "DM": "1:DM",
      "DO": "1:DO",
      "EC": "593:EC",
      "EG": "20:EG",
      "SV": "503:SV",
      "GQ": "240:GQ",
      "ER": "291:ER",
      "EE": "372:EE",
      "ET": "251:ET",
      "FK": "500:FK",
      "FO": "298:FO",
      "FJ": "679:FJ",
      "FI": "358:FI",
      "FR": "33:FR",
      "GF": "594:GF",
      "PF": "689:PF",
      "GA": "241:GA",
      "GM": "220:GM",
      "GE": "995:GE",
      "DE": "49:DE",
      "GH": "233:GH",
      "GI": "350:GI",
      "GR": "30:GR",
      "GL": "299:GL",
      "GD": "1:GD",
      "GP": "590:GP",
      "GU": "1:GU",
      "GT": "502:GT",
      "GN": "224:GN",
      "GW": "245:GW",
      "GY": "592:GY",
      "HT": "509:HT",
      "VA": "39:VA",
      "HN": "504:HN",
      "HK": "852:HK",
      "HU": "36:HU",
      "IS": "354:IS",
      "IN": "91:IN",
      "ID": "62:ID",
      "IR": "98:IR",
      "IQ": "964:IQ",
      "IE": "353:IE",
      "IM": "44:IM",
      "IL": "972:IL",
      "IT": "39:IT",
      "CI": "225:CI",
      "JM": "1:JM",
      "JP": "81:JP",
      "JE": "44:JE",
      "JO": "962:JO",
      "KZ": "7:KZ",
      "KE": "254:KE",
      "KI": "686:KI",
      "KW": "965:KW",
      "KG": "996:KG",
      "LA": "856:LA",
      "LV": "371:LV",
      "LB": "961:LB",
      "LS": "266:LS",
      "LR": "231:LR",
      "LY": "218:LY",
      "LI": "423:LI",
      "LT": "370:LT",
      "LU": "352:LU",
      "MO": "853:MO",
      "MK": "389:MK",
      "MG": "261:MG",
      "MW": "265:MW",
      "MY": "60:MY",
      "MV": "960:MV",
      "ML": "223:ML",
      "MT": "356:MT",
      "MH": "692:MH",
      "MQ": "596:MQ",
      "MR": "222:MR",
      "MU": "230:MU",
      "YT": "262:YT",
      "MX": "52:MX",
      "FM": "691:FM",
      "MD": "373:MD",
      "MC": "377:MC",
      "MN": "976:MN",
      "ME": "382:ME",
      "MS": "1:MS",
      "MA": "212:MA",
      "MZ": "258:MZ",
      "NA": "264:NA",
      "NR": "674:NR",
      "NP": "977:NP",
      "NL": "31:NL",
      "AN": "599:AN",
      "NC": "687:NC",
      "NZ": "64:NZ",
      "NI": "505:NI",
      "NE": "227:NE",
      "NG": "234:NG",
      "NU": "683:NU",
      "NF": "672:NF",
      "KP": "850:KP",
      "MP": "1:MP",
      "NO": "47:NO",
      "OM": "968:OM",
      "PK": "92:PK",
      "PW": "680:PW",
      "PS": "970:PS",
      "PA": "507:PA",
      "PG": "675:PG",
      "PY": "595:PY",
      "PE": "51:PE",
      "PH": "63:PH",
      "PN": "870:PN",
      "PL": "48:PL",
      "PT": "351:PT",
      "PR": "1:PR",
      "QA": "974:QA",
      "RE": "262:RE",
      "RO": "40:RO",
      "RU": "7:RU",
      "RW": "250:RW",
      "BL": "590:BL",
      "SH": "290:SH",
      "KN": "1:KN",
      "LC": "1:LC",
      "MF": "590:MF",
      "PM": "508:PM",
      "VC": "1:VC",
      "WS": "685:WS",
      "SM": "378:SM",
      "ST": "239:ST",
      "SA": "966:SA",
      "SN": "221:SN",
      "RS": "381:RS",
      "SC": "248:SC",
      "SL": "232:SL",
      "SG": "65:SG",
      "SX": "1:SX",
      "SK": "421:SK",
      "SI": "386:SI",
      "SB": "677:SB",
      "SO": "252:SO",
      "ZA": "27:ZA",
      "KR": "82:KR",
      "SS": "211:SS",
      "ES": "34:ES",
      "LK": "94:LK",
      "SD": "249:SD",
      "SR": "597:SR",
      "SJ": "47:SJ",
      "SZ": "268:SZ",
      "SE": "46:SE",
      "CH": "41:CH",
      "SY": "963:SY",
      "TW": "886:TW",
      "TJ": "992:TJ",
      "TZ": "255:TZ",
      "TH": "66:TH",
      "TL": "670:TL",
      "TG": "228:TG",
      "TK": "690:TK",
      "TO": "676:TO",
      "TT": "1:TT",
      "TN": "216:TN",
      "TR": "90:TR",
      "TM": "993:TM",
      "TC": "1:TC",
      "TV": "688:TV",
      "UG": "256:UG",
      "UA": "380:UA",
      "AE": "971:AE",
      "GB": "44:GB",
      "US": "1:US",
      "UY": "598:UY",
      "VI": "1:VI",
      "UZ": "998:UZ",
      "VU": "678:VU",
      "VE": "58:VE",
      "VN": "84:VN",
      "WF": "681:WF",
      "EH": "212:EH",
      "YE": "967:YE",
      "ZM": "260:ZM",
      "ZW": "263:ZW"
    },
    states: [
        {
            code: "AL",
            state: "Alabama"
        },
        {
            code: "AK",
            state: "Alaska"
        },
        {
            code: "AS",
            state: "American Samoa"
        },
        {
            code: "AZ",
            state: "Arizona"
        },
        {
            code: "AR",
            state: "Arkansas"
        },
        {
            code: "CA",
            state: "California"
        },
        {
            code: "CO",
            state: "Colorado"
        },
        {
            code: "CT",
            state: "Connecticut"
        },
        {
            code: "DE",
            state: "Delaware"
        },
        {
            code: "DC",
            state: "District Of Columbia"
        },
        {
            code: "FL",
            state: "Florida"
        },
        {
            code: "GA",
            state: "Georgia"
        },
        {
            code: "GU",
            state: "Guam"
        },
        {
            code: "HI",
            state: "Hawaii"
        },
        {
            code: "ID",
            state: "Idaho"
        },
        {
            code: "IL",
            state: "Illinois"
        },
        {
            code: "IN",
            state: "Indiana"
        },
        {
            code: "IA",
            state: "Iowa"
        },
        {
            code: "KS",
            state: "Kansas"
        },
        {
            code: "KY",
            state: "Kentucky"
        },
        {
            code: "LA",
            state: "Louisiana"
        },
        {
            code: "ME",
            state: "ME"
        },
        {
            code: "MD",
            state: "Maryland"
        },
        {
            code: "MA",
            state: "Massachusetts"
        },
        {
            code: "MI",
            state: "Michigan"
        },
        {
            code: "MN",
            state: "Minnesota"
        },
        {
            code: "MS",
            state: "Mississippi"
        },
        {
            code: "MO",
            state: "Missouri"
        },
        {
            code: "MT",
            state: "Montana"
        },
        {
            code: "NE",
            state: "Nebraska"
        },
        {
            code: "NV",
            state: "Nevada"
        },
        {
            code: "NH",
            state: "New Hampshire"
        },
        {
            code: "NJ",
            state: "New Jersey"
        },
        {
            code: "NM",
            state: "New Mexico"
        },
        {
            code: "NY",
            state: "New York"
        },
        {
            code: "NC",
            state: "North Carolina"
        },
        {
            code: "ND",
            state: "North Dakota"
        },
        {
            code: "MP",
            state: "Northern Mariana Islands"
        },
        {
            code: "OH",
            state: "Ohio"
        },
        {
            code: "OK",
            state: "Oklahoma"
        },
        {
            code: "OR",
            state: "Oregon"
        },
        {
            code: "PA",
            state: "Pennsylvania"
        },
        {
            code: "PR",
            state: "Puerto Rico"
        },
        {
            code: "RI",
            state: "Rhode Island"
        },
        {
            code: "SC",
            state: "South Carolina"
        },
        {
            code: "SD",
            state: "South Dakota"
        },
        {
            code: "TN",
            state: "Tennessee"
        },
        {
            code: "TX",
            state: "Texas"
        },
        {
            code: "UM",
            state: "United States Minor Outlying Islands"
        },
        {
            code: "UT",
            state: "Utah"
        },
        {
            code: "VT",
            state: "Vermont"
        },
        {
            code: "VI",
            state: "Virgin Islands"
        },
        {
            code: "VA",
            state: "Virginia"
        },
        {
            code: "WA",
            state: "Washington"
        },
        {
            code: "WV",
            state: "West Virginia"
        },
        {
            code: "WI",
            state: "Wisconsin"
        },
        {
            code: "WY",
            state: "Wyoming"
        }
    ],
    state_to_phone: ['US'],
    forbidden_countries: ["CU","IR","IQ","LY","KP","SO","SD","SY","YE"]
  },
  mutations: {
  //   setThisVue(state, payload){
  //     state.thisVue = payload;
  // },
  setToken(state, data){
    state.token = data
  },
  setLanguage(state, data){
    state.language = data
  },
  setFormdata(state, data){
    state.formdata = data
  },
  setAffId(state, data){
    state.aff_id = data
  },
  setAffCId(state, data){
    state.aff_cid = data
  },
  },
  actions: {
  //   setThisVue(context, payload){
  //     context.commit('setThisVue', payload);
  // },
  setToken(context, data){
    // let _this = context.state.thisVue;
    context.commit('setToken', data);
  },
  setLanguage(context, data){
    // let _this = context.state.thisVue;
    context.commit('setLanguage', data);
  },
  setFormdata(context, data){
    // let _this = context.state.thisVue;
    context.commit('setFormdata', data);
  },
  setAffId(context, data){
    // let _this = context.state.thisVue;
    context.commit('setAffId', data);
  },
  setAffCId(context, data){
    // let _this = context.state.thisVue;
    context.commit('setAffCId', data);
  },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})