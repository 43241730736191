<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Employment Information</div></v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Do you have a current or previous employer?</div>
        <v-radio-group
            v-model="formdata.current_previous_employer_bool"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.current_previous_employer_bool == true">
    <v-row>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Job Title</div>
        <v-text-field
        v-model="formdata.job_title"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Job Title"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Employer Name<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.employer_name"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Employer Name"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 1<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.employer_address_line_1"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 1"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 2</div>
        <v-text-field
        v-model="formdata.employer_address_line_2"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 2"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Employer City<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.employer_city"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Employer CIty"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">State/Province/Region<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.employer_state"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="State/Province/Region"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.employer_country"
            @change="changeCountry()"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="country"
            item-value="code"
            :items="countries"
            item-color="#00a34e"
            placeholder="Select Country"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country Code + Phone<span class="asterisk">*</span></div>
        <span class="phone-style">
        <vue-tel-input-vuetify
          :valid-characters-only="true"
          v-model="formdata.employer_phone_number"
          label=""
          color="grey"
          :rules="[formRules.required, valid_epn || 'Invalid phone number']"
          height="45"
          outlined
          type="tel"
          placeholder="Enter your phone number"
          @input="onInputEPN"
        />
        </span>
    </v-col>
    </v-row>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        current_previous_employer_bool: false,
        job_title: null,
        employer_name: null,
        employer_address_line_1: null,
        employer_address_line_2: null,
        employer_city: null,
        employer_state: null,
        employer_country: null,
        employer_phone_number: null,
    },
    countries: [],
    valid_epn: false,
    default_pn: [],
    formatted_epn: null,
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        this.countries = this.$store.state.countries
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.employment || {}));
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            let data = JSON.parse(JSON.stringify(this.formdata))
            data.employer_phone_number = this.formatted_epn;
            formdata.employment = data;
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        changeCountry(){
            let ctf = this.$store.state.country_to_phone[this.formdata.employer_country]
            if(ctf){
                let prefix = ctf.split(':')
                this.formdata.employer_phone_number = '+' + prefix[0];
            }
        },
        onInputEPN(formattedNumber, { number, valid, country }) {
            console.log(formattedNumber, number, valid, country)
            this.valid_epn = valid
            this.formatted_epn = number.e164
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
</style>