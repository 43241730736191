import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import "./assets/css/global.css"
import mixins from './mixins.js'
import store from './store';
import OtpInput from '@bachdgvn/vue-otp-input';
import VueJsonToCsv from 'vue-json-to-csv'
import HorizontalStepper from 'vue-stepper';
import { Translator } from 'vue-google-translate';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
const FadeAwayMessage = () => import("./components/Alerts/FadeAwayMessage");
Vue.config.productionTip = false
Vue.mixin(mixins)
Vue.use(VueTelInputVuetify, {
    vuetify,
  });
Vue.component("v-otp-input", OtpInput);
Vue.component("vue-json-to-csv", VueJsonToCsv);
Vue.component('fade-away-message-component', FadeAwayMessage);
Vue.component('horizontal-stepper', HorizontalStepper);
Vue.component('Translator', Translator);

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')