<template>
  <div>
    <div class="outer-content">
    <!-- <div class="bg-content"></div> -->
      <div class="inner-content">
       <v-toolbar elevation="0" class="pa-2" color="transparent" max-height="20">
        <v-img src="../../assets/img/usa-esta-logo.svg" class="logo-style" max-width="160"></v-img>
        <v-spacer></v-spacer>
        <Translation />
       </v-toolbar>
    </div>
  </div>
  <div class="content">
    <section class="section">
        <div class="container">
            <div class="pt-5">
            <h1>Privacy Policy</h1>
            <div style="text-align: justify">
            <p>This Privacy Policy describes how your information is collected, used, and disclosed by UNOBIT, LLC (“UNOBIT”, “we,” “us,” or “our”) in connection with your use of our websites, indicated under the heading [Websites – (Covered Entities)]&nbsp;(“Websites”) and our services. The scope of this Privacy Policy covers both your use of the UNOBIT, LLC website(s) and any other ways in which you may interact with UNOBIT, LLC, such as when you speak to us on the phone or through a remote video call. It also describes the choices available to you regarding our use of your personal information. Information for California residents and individuals located in the European Union are also included within this policy.</p>
            <h2><strong>DEFINITIONS</strong></h2>
            <p><strong>Personal Data:</strong></p>
            <p>This means any information relating to an identified or identifiable natural person (“data subject/user”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person. As used in this Privacy Policy, Personal Data or Personal Information means information that relates, and is capable of being linked, to a particular person.&nbsp; It does not include de-identified or aggregate information.</p>
            <h5><strong>Data subject/user:</strong></h5>
            <p>Refers to any identified or identifiable natural person, whose personal data is processed by UNOBIT, LLC (the controller responsible for the processing).</p>
            <h5><strong>Processing:</strong></h5>
            <p>Is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</p>
            <h5><strong>Consent of the data subject/user:</strong></h5>
            <p>Is any freely given, specific, informed and unambiguous indication of the data subject/user's wishes by which he or she, by a statement or by specific affirmative action, signifies agreement to the processing of personal data relating to him or her.</p>
            <h2><strong>INFORMATION WE COLLECT</strong></h2>
            <p>We or our service providers may collect Personal Data and data that does not specifically identify you when you use our Websites or services. In this section, we explain what types of Personal Information we may collect about consumers. The Personal Information collected depends on the type of interaction we have with a consumer (such as calling, or visiting our Websites), the types of products a consumer considers or purchases, and what information UNOBIT, LLC’ customers choose to share with us. This section includes all the information that we may collect about consumers, but we will rarely collect all of these types of information about any single individual. UNOBIT, LLC potentially collects the following categories of information about consumers, some of which may be collected or maintained in a manner such that it can be considered Personal Information:</p>
            <p><strong>Identifiers:</strong>&nbsp;Examples of the types of identifiers that UNOBIT, LLC may collect include real names, nicknames, postal addresses, online identifiers, Internet Protocol addresses (“IP addresses”), email addresses, account numbers, Social Security numbers, driver’s license numbers, passport numbers, and other government identification numbers.</p>
            <p><strong>Other Types of Information:</strong>&nbsp; Examples of other types of information include physical characteristics or descriptions of you, telephone number, education, employment, financial information including bank account, credit card, and debit card numbers. It also includes additional information provided to UNOBIT, LLC directly by you, such as when you complete a questionnaire, answer a survey, or receive a consultation. This category includes information identified as Personal Information in California Civil Code Section 1798.80(e) that is not otherwise categorized.</p>
            <p><strong>Characteristics Protected Under Some Laws:</strong>&nbsp;Examples of the types of characteristics that UNOBIT, LLC may collect include age, national origin, citizenship, marital status, medical information, sex, sexual orientation, and veteran or military status.</p>
            <p><strong>Internet Information:</strong>&nbsp;Examples of the types of internet or other electronic network activity information that UNOBIT, LLC may collect includes browsing history, search history, information about the device the consumer is using to access our Websites or applications, and information regarding a consumer’s interaction with UNOBIT, LLC or our partners’ websites, applications, or advertisements. We may also collect information from visitors to our Websites such as browser settings, operating systems, referring/exit pages, and clickstream data. When we send you an email, UNOBIT, LLC may also collect information, including about how you interact with that email.</p>
            <p><strong>Geolocation Information:</strong>&nbsp;UNOBIT, LLC may collect information about where a consumer resides or is otherwise located.</p>
            <p><strong>Audio and visual information:</strong>&nbsp;UNOBIT, LLC may make audio recordings of calls with consumers. We may make visual recordings of consumers when they visit our premises or when consumers agree that UNOBIT, LLC may take their photograph or videotape them. We may also collect visual images of consumers when they are provided to us in connection with a product, such as when they are submitted as part of a trademark order. We do not collect thermal or olfactory information.</p>
            <p><strong>Professional and Employment-Related Information:</strong>&nbsp;Examples of the types of professional and employment-related information UNOBIT, LLC may collect includes information about professional status and affiliations, employment history, and employment status requested by your Visa application.</p>
            <p>We do not collect biometric or education information that is maintained by an educational institution or party acting on an educational institution’s behalf. We also do not collect inferences about consumers, although as described below, we may internally create inferences based on the information that we do collect.</p>
            <h5><strong>Personal Data:</strong></h5>
            <p>We may collect Personal Data from you when you purchase our products or services, register to use our services, register to become an affiliate, or otherwise communicate with us. The Personal Data that we collect through our Websites may include, but is not limited to, your first and last name, mailing address, email address, phone number, payment card information, and any information contained in your communications with us.</p>
            <h5><strong>Professionals (including Lawyers and other Professionals):</strong></h5>
            <p>We may collect information about you from the independent professionals with whom you may schedule a consult with through our Legal Review service or other product offerings.&nbsp; An example of the type of information that we may receive from these professionals includes the fact that a consult has occurred.&nbsp; While we cannot control which or what information the Lawyers may share with us, UNOBIT does not intend to collect any information from lawyers where the collection would result in a waiver of the lawyer-client privilege.</p>
            <h5><strong>Information collected via Technology:</strong></h5>
            <p>We may automatically collect information about your use of our Websites and services, such as the Websites you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to our services, the time and date of access, and other information that may not personally identify you. We track this information using cookies, small text files, or other technologies. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect information about that user’s visit to our Websites or use our services and keep a record of the user’s preferences when visiting our Websites or using our services, both on an individual and aggregate basis.</p>
            <p>The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session until you delete them, while session cookies expire when you close your browser. You may be able to refuse or delete cookies using the appropriate settings on your browser. However, if you refuse or delete cookies, you may not be able to use the full functionality of our Websites or services. If you use another browser or device, you may need to review the settings for that browser or device to refuse or delete cookies on that browser or device.</p>
            <p>We may also work with third parties that collect information about your use of the Websites and other websites or applications over time for non-advertising purposes. We use Google Analytics and other third-party services to monitor and improve the performance of our Websites. For more information about how Google Analytics collects and uses information when you visit our Websites, please visit&nbsp;<a href="https://google.com/policies/privacy/partners" target="_blank">www.google.com/policies/privacy/partners</a>. You can learn how to opt-out of our website’s cookies at any time by using the link below:</p>
            <p><a class="cli_settings_button g-cookie-button" href="#" target="_parent">Disable Cookies</a></p>
            <p><a class="g-cookie-manual" href="https://wikihow.com/Disable-Cookies" target="_blank">Manually Disable Cookies (If the above button does not work)</a></p>
            <h2><strong>HOW WE USE AND SHARE YOUR INFORMATION</strong></h2>
            <h5><strong>Personal Data:</strong></h5>
            <p>Except as otherwise stated in this Privacy Policy,&nbsp;we do not sell, trade, rent or share your Personal Data with third parties for marketing purposes without your consent. We share Personal Data:</p>
            <ol>
            <li>with vendors who are performing services for the Company at our direction and in accordance with our Privacy Policy;</li>
            <li>with any affiliates (If necessary to perform the service);</li>
            <li>where we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request;</li>
            <li>to enforce our Terms of Use, including investigating potential violations;</li>
            <li>to address fraud, security or technical concerns;</li>
            <li>to protect against harm to the rights, property, or safety of our users or the public, as required or permitted by law;</li>
            <li>in the event we undergo a business transaction such as a merger, acquisition, or sale of all or a portion of our assets, including in bankruptcy;</li>
            <li>pursuant to a subpoena, court order, governmental inquiry, or other legal process or as otherwise required by law, or to protect our rights or the rights of third parties;</li>
            <li>Professionals (including Lawyers and other Professionals):&nbsp;UNOBIT, LLC may share consumer information with the independent professionals that can be accessed through products such as our legal reviews and lawyer-assisted products; and</li>
            <li>Government Entities:&nbsp; UNOBIT, LLC is not affiliated with any governmental organization but as part of the services offered, the client may have an application that is sent directly to the appropriate agency and UNOBIT, LLC as, and/or UNOBIT, LLC may share information about consumers with government entities in order to fulfill the products purchased by our customers.&nbsp; Certain Personal Information becomes public record when documents are filed with the federal or state government, or with a court. For example, an applicant’s personal information and files are sent to the US Department of Homeland Security (DHS) or Unites States Citizen and Immigration Services (USCIS) or other pertinent agency and are no longer under our control when applications and petitions are filed. This Privacy Policy does not cover these or similar third-party actions.</li>
            </ol>
            <p>If we sell all or part of our business, your Personal Data may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post an updated privacy policy on our Websites. We suggest that you check the Websites periodically to learn about how your information is used and disclosed.</p>
            <p>We use the Personal Data you provide to us for the following purposes:</p>
            <ol>
            <li>to help us communicate with you, such as to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional offers;</li>
            <li>to provide our services to you or to the owner of the account associated with your application;</li>
            <li>to operate, maintain, and improve our Websites and Services;</li>
            <li>to comply with applicable legal requirements; and</li>
            <li>to enforce our Terms of Use and otherwise protect our interests;</li>
            <li>as otherwise disclosed at the time such information is collected.</li>
            </ol>
            <p>We may also use any Personal Data you provide to us when registering for our services on our Websites or purchasing a product on our Websites to advertise or market other products or services that we think may be of interest to you.</p>
            <h3><strong>Automatically Collected Information</strong></h3>
            <p>We may use technology to automatically collect certain information from you when you use our Websites or services (“Automatically Collected Information”). In general, we use Automatically Collected Information to help us improve our service and customize the user experience. We also aggregate Automatically Collected Information in order to track trends and analyze usage patterns on the Websites and our service. This Privacy Policy does not limit in any way our use or disclosure of Automatically Collected Information, and we reserve the right to use and disclose such Automatically Collected Information to our partners, advertisers and other third parties at our discretion.</p>
            <h2><strong>HOW WE PROTECT INFORMATION</strong></h2>
            <p>We implement security measures designed to protect your information from unauthorized access. Where needed, your account is protected by your account password, and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.</p>
            <h2><strong>YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</strong></h2>
            <p>You have the right at any time to prevent us from sending email marketing communications to you. When we send promotional email communications to a user, the user can opt-out of receiving such communications by following the unsubscribe instructions provided in each promotional email. You can also indicate that you do not wish to receive marketing communications from us here:&nbsp;<a href="mailto:support@unobitbookings.com">support@unobitbookings.com</a>. Please note that notwithstanding the promotional preferences you choose, we may continue to send you administrative emails including, for example, communications about updates to our Privacy Policy.</p>
            <h2><strong>LINKS TO OTHER WEBSITES</strong></h2>
            <p>As part of the Service, we may provide links to other websites or applications. We are not responsible for the privacy practices employed by the owners of those websites and applications or the information or content they contain. This Privacy Policy applies solely to information collected by us through these Websites and our service. Therefore, this Privacy Policy does not apply to your use of a third-party website accessed by selecting a link on our Websites or via our service. We encourage our users to read the privacy statements of other websites before proceeding to use them.</p>
            <h2><strong>Information for EU Customers and Visitors.</strong></h2>
            <p>If you are located in the European Union (“EU”), this section provides information regarding your rights, and UNOBIT, LLC’s responsibilities, and additional information regarding the processing of your personal data.</p>
            <h2><strong>Lawful Basis of Processing</strong></h2>
            <p>UNOBIT, LLC processes personal data of persons in the EU based on the lawful basis outlined below.</p>
            <p>Where multiple bases are provided, the basis that UNOBIT, LLC relies on for any particular processing will be context dependent.</p>
            <table style="width: 100%;" cellspacing="0" cellpadding="0" border="1">
            <thead>
            <tr>
            <th scope="col" width="50%">
            <div><strong>Purpose of Processing</strong></div>
            <br />
            <div>(Described in the section above entitled “How We Use the Information We Collect About Consumers.”)</div>
            </th>
            <th scope="col" width="50%"><br /><strong>Legal Basis for Processing</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td>Providing Products and Services</td>
            <td>Performance of Contract, Consent, Legitimate Interest</td>
            </tr>
            <tr>
            <td>Security</td>
            <td>Legitimate Interest</td>
            </tr>
            <tr>
            <td>Meeting Legal Requirements</td>
            <td>Compliance with Legal Obligation</td>
            </tr>
            <tr>
            <td>Marketing</td>
            <td>Consent, Legitimate Interest</td>
            </tr>
            <tr>
            <td>Product Development</td>
            <td>Legitimate Interest</td>
            </tr>
            <tr>
            <td>Analytics</td>
            <td>Legitimate Interest</td>
            </tr>
            <tr>
            <td>Operational Purposes</td>
            <td>Consent, Legitimate Interest, Compliance with Legal Obligations</td>
            </tr>
            <tr>
            <td>Sharing with Business Partners</td>
            <td>Performance of Contract, Consent</td>
            </tr>
            <tr>
            <td>Financial Reporting and Other Internal Uses</td>
            <td>Legitimate Interest</td>
            </tr>
            <tr>
            <td>Business Transfers</td>
            <td>Legitimate Interest, Compliance with Legal Obligation</td>
            </tr>
            </tbody>
            </table>
            <h2><strong>EU User Rights</strong></h2>
            <p>If you are located in the EU, you have the following rights with respect to how UNOBIT, LLC processes your personal data.</p>
            <ol>
            <li>You have the right to request a copy of the personal data that UNOBIT, LLC has about you;</li>
            <li>You have the right to request that UNOBIT, LLC correct the information about you if you believe it to be inaccurate;</li>
            <li>You have the right to request that UNOBIT, LLC erase or refrain from using your personal data.&nbsp; This is not an absolute right and, in some circumstances, UNOBIT, LLC may decline to erase or limit its usage of your data, such as when UNOBIT, LLC has an on-going business relationship with you or we are legally required to retain the information.&nbsp; If UNOBIT, LLC cannot honor your request, UNOBIT, LLC will inform you of this fact as well as the reasons for UNOBIT, LLC’s decision;</li>
            </ol>
            <p>To exercise any of these rights, please submit the request using the form located&nbsp;<a href="https://privacy.UNOBIT.com/privacy-policy-compliance" rel="noopener noreferrer" target="_blank">here</a>.&nbsp;&nbsp;Alternatively, you may contact Customer Support at&nbsp;<a href="mailto:support@unobitbookings.com">support@unobitbookings.com</a>.</p>
            <p>If you are located in the EU, you also have the right to raise any objections regarding the processing of your personal data and to raise any concerns you may have about UNOBIT, LLC’s processing of your personal data.&nbsp; You may make an objection or raise any other concerns by contacting Customer Support at&nbsp;<a href="mailto:support@unobitbookings.com">support@unobitbookings.com</a>.</p>
            <p>While we hope that UNOBIT, LLC can resolve any of your privacy concerns to your satisfaction after you contact us directly, you also have the right to file a complaint regarding UNOBIT, LLC’s handling of your personal data with the Information Commissioner’s Office, the UK’s independent Data Protection Authority.&nbsp; Their contact information is:</p>
            <p>Information Commissioner’s Office<br>
            Wycliffe House<br>
            Water Lane<br>
            Wilmslow<br>
            Cheshire<br>
            SK9 5AF</p>
            <p><a href="https://ico.org.uk/global/contact-us/email/">https://ico.org.uk/global/contact-us/email/</a></p>
            <p>+44 1625 545 700</p>
            <h2><strong>Websites – (Covered Entities)</strong></h2>
            <p>This website is covered under the UNOBIT, LLC privacy policy. Please&nbsp;<a href="https://unobitbookings.com/privacy-policy/" rel="noopener noreferrer" target="_blank">click here&nbsp;</a>to review a list of other covered websites.</p>
            <h2><strong>CHANGES TO OUR PRIVACY POLICY</strong></h2>
            <p>The Company reserves the right to change this Privacy Policy at any time. We will notify you of changes to our Privacy Policy by posting an updated policy on our Websites with a new effective date. You should periodically check the Websites to review our Privacy Policy for updates.</p>
            <h2><strong>Children’s Privacy</strong></h2>
            <p>Our Websites and our services are not directed to anyone under the age of 18. We do not knowingly collect or solicit information from anyone under the age of 18 or allow anyone under the age of 18 to sign up for the service and/or fill out an application.</p>
            <p>Parents may wish to arrange for travel requirements for their minor child. We will process these applications with the same security measures used for all our services. In this instance, the data is gathered &amp; collected from the parent/guardian and not from the minor.</p>
            <p>In the event that we learn that we have gathered Personal Data from anyone under the age of 13, we will promptly delete such Personal Data. If you are the parent of a child who is younger than 13 years old, and you have provided us with Personal Data about your child with relation to our travel services and want to ensure that it has been deleted, please contact us at&nbsp;<a href="mailto:support@unobitbookings.com">support@unobitbookings.com</a></p>
            <h2><strong>Access to Personal Information</strong></h2>
            <p>You may review, update, or request modification of any Personal Data that you have provided to us through our Websites or services by contacting us using the contact information provided below.</p>
            <h2><strong>Data Retention</strong></h2>
            <p>We retain your personal data for the duration of the customer relationship, if any. We also retain your personal data for 12 consecutive months after our last interaction with you.</p>
            <h2><strong>Online Behavioral Advertising</strong></h2>
            <p>We may partner with third-party organizations, such as ad networks, which may use cookies, pixel tags, beacons, clear gifs, and/or other technologies to collect information about your visits to our Websites and other non-affiliated sites or applications over time, including information about relationships among the different browsers and devices you use. These third-party organizations collect this information to provide you with relevant advertising based on your browsing activities and interests. This type of advertising is known as interest-based advertising.</p>
            <p>We adhere to the DAA’s Self-Regulatory Principles in connection with this interest-based advertising activity. You may visit&nbsp;<a href="https://optout.aboutads.info/" rel="noopener noreferrer" target="_blank">www.optout.aboutads.info</a>&nbsp;to learn more about interest-based advertising and how to opt-out of this form of advertising on your web browser by companies participating in the Digital Advertising Alliance (“DAA”). If you use a different browser, erase cookies from your browser, or use a different mobile device, you may need to renew your “opt-out” choices. If you choose to opt-out of receiving interest-based ads, you will continue to see advertisements on websites serving interest-based ads; however, these advertisements will not be tailored to your interests.</p>
            <h2><strong>California Users</strong></h2>
            <p>If you are a resident of California, note that we have previously indicated that we do not sell, rent, share or trade your Personal Data. However, the law requires us to notify you that you may have certain additional rights with respect to the processing of your Personal Data. You may have the right to request that we delete any of your Personal Data. You also have the right to opt-out of the sale* of your Personal Data. To exercise this right, please use the following link:&nbsp;<a href="https://unobitbookings.com/" rel="noopener noreferrer" target="_blank">https://unobitbookings.com</a>&nbsp;and select the “Do Not Sell my Personal Information” from the Select Topic Dropdown menu.</p>
            <p>You also have the right to request that we disclose to you the following information:</p>
            <ol>
            <li>The categories of Personal Data that we collect, sell, or disclose for a business purpose;</li>
            <li>The categories of sources from which we collect Personal Data about you;</li>
            <li>The business or commercial purposes for which we collect your Personal Data;</li>
            <li>The specific pieces of Personal Data we have collected about you;</li>
            <li>The categories of third parties to which we may sell Personal Data.</li>
            </ol>
            <p>You have the right to know the categories of Personal Data that we may sell or disclose for a business purpose. We disclose all information, including all Personal Data that we collect, to the individual associated with that data. We have identified these categories below:</p>
            <p>Categories of Personal Data We May Collect (*Please note that we do not sell, rent, trade or share Personal Data as indicated above.)</p>
            <ol>
            <li>Your name;</li>
            <li>Alias;</li>
            <li>Postal Address;</li>
            <li>Unique personal identifiers;</li>
            <li>Online identifiers;</li>
            <li>IP address;</li>
            <li>Email address;</li>
            <li>Account name;</li>
            <li>Browsing history;</li>
            <li>Search history;</li>
            <li>Information regarding your interactions with websites, applications, or advertisements;</li>
            <li>Geolocation data;</li>
            <li>Electronic information;</li>
            <li>Professional or employment-related information; and</li>
            <li>Education information.</li>
            </ol>
            <p>Categories of Personal Data that We May Disclose For a Business Purpose:</p>
            <ol>
            <li>Your name;</li>
            <li>Alias;</li>
            <li>Postal Address;</li>
            <li>Unique personal identifiers;</li>
            <li>Online identifiers;</li>
            <li>IP address;</li>
            <li>Email address;</li>
            <li>Account name;</li>
            <li>Browsing history;</li>
            <li>Search history;</li>
            </ol>
            <p>Information regarding your interactions with websites, applications, or advertisements;</p>
            <ol>
            <li>Geolocation data;</li>
            <li>Electronic information;</li>
            <li>Professional or employment-related information; and</li>
            <li>Education information.</li>
            </ol>
            <p>You also have the right not to be discriminated against for exercising any of the rights identified in this section. To exercise any of the rights identified in this section, please use the contact information provided below.</p>
            <h2><strong>International Users</strong></h2>
            <p>If you visit our Websites or use our services from locations outside of the United States, please be aware that the information you provide to us through your use of our Websites or our services may be transferred to and processed in countries other than the country from which you viewed our Websites or used our services, including the United States. These countries may not offer equivalent protection for your Personal Data as those available in the country from which you accessed our Websites or used our services. The method we use to transfer Personal Data from the EU to the US is through the form they fill out and transfer it to our server.</p>
            <p>If you visit our Websites or use our services from the European Economic Area (“EEA”), please note that you may have additional rights with respect to the processing of your Personal Data. You may have the right to request that we provide you with access to your Personal Data. You may also have the right to request that we correct any errors relating to your Personal Data, that we erase your Personal Data, that we restrict the processing of your Personal Data, and that we provide you with a copy of your Personal Data in a structured, machine-readable format. You may also have the right to request that we provide your Personal Data to another organization.</p>
            <p>In most instances, we process Personal Data with the data subject’s consent. We obtain your consent to process your Personal Data when you file an application. In addition to obtaining your consent to process your Personal Data, we also may process such information to provide our services to you. We may also process Personal Data where required to do so by applicable law or where it is in our legitimate interest to do so and such interests are not overridden by the data subject’s data protection rights. Our legitimate interests may include acting on your behalf to assist in the completion of your applications as you have contracted us to do, improving and developing new products and services, fighting fraud, and protecting our business interests and rights. You have the right to object to our processing of your Personal Data on the basis of our legitimate interests or to withdraw your consent to our processing of your Personal Data.</p>
            <p>To exercise your rights, please submit a request using the contact information provided below. Please note that in some instances, we may be required by the applicable law to restrict our disclosure of your Personal Information or to retain your Personal Information after you have requested that we delete it. In such instances, we will comply with the applicable legal requirements.</p>
            <p>If you have concerns relating to the processing of your Personal Data, please let us know using the contact information provided below. If we are unable to resolve your concerns, you have the right to file a complaint with the data protection authority where you live, work, or where you believe a breach of applicable law may have occurred.</p>
            <p>We do not use automatic decision-making (i.e., making decisions solely by automated means without any human involvement) or profiling (i.e., automated processing of personal information to evaluate certain aspects of an individual).</p>
            <p>In the event that we experience a personal data breach, we will notify you and pertinent agencies of this incident in accordance with applicable legal requirements.</p>
            <h2><strong>CONTACT US</strong></h2>
            <p>This Privacy Policy is incorporated into our&nbsp;<a href="https://unobitbookings.com/terms-conditions" target="_parent">Terms of Use,</a> and therefore governs your use of UNOBIT, LLC’ services. By using UNOBIT, LLC’ services on any of the covered entities, you accept the terms of this Privacy Policy. If you have any questions regarding this Privacy Policy or the practices of our Websites, please contact us by sending an email to&nbsp;<a href="mailto:support@unobitbookings.com">support@unobitbookings.com</a>. You can also visit our Website at www.unobitbookings.com or call&nbsp;for more information.</p>
            </div>
            </div>
        </div>
    </section>
    <Footer />
  </div>
  </div>
  
</template>

<script>
import Translation from '../../components/Utils/Translation.vue';
import Footer from '../../components/Footer/Footer.vue';
export default {
  name: "PaymentThankYou",
  components: {
    Translation,
    Footer,
  },
  data: () => ({
    
  }),
  methods: {
    startNewApplication(){
      this.$router.push("/");
      this.$router.go();
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.outer-content {
  background-color: #ffffff !important;
}
.bg-content {
  /* background: url("../../assets/img/background.png"); */
   position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-content{
    background-color: #ffffff !important;
    padding: 0 13%;
}
.content{
    margin: 30px 15%;
}
.container{
    text-align: justify;
}
h1 {
    font-size: 38px;
}
h2 {
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 28px;
}
h5 {
    font-size: 20px;
    padding-top: 15px;
}
table tr td, table th {
    padding: 5px;
}
@media only screen and (max-width: 600px) {
    .inner-content{
        padding: 0 3%;
    }
    .content{
        margin: 80px 0;
    }
}
</style>
