<template>
  <div>
    <div>
    <!-- <v-app-bar
      absolute
      color="white"
      inverted-scroll
      scroll-target="#scrolling-techniques-7"
    >

      <v-toolbar-title><v-img src="../../assets/img/nzeta-logo.png" width="140"></v-img></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="showTranslator">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar> -->
    <div class="outer-content">
    <!-- <div class="bg-content"></div> -->
      <div class="inner-content">
       <v-toolbar elevation="0" class="pa-2" color="transparent" max-height="20">
        <v-img src="../../assets/img/usa-esta-logo.svg" class="logo-style" max-width="160" ></v-img>
        <v-spacer></v-spacer>
      <!-- <v-chip @click="showTranslator" outlined color="#dc2a5f"><span class="notranslate">{{cur_language}}</span></v-chip> -->
      <Translation v-if="render" />
       </v-toolbar>
       <v-row class="text-h5 text-center title mb-2" justify="center">
            Welcome, you are entitled to an Electronic Entry Permit (ESTA) to the United States <v-img src="../../assets/img/usa-flag.png" class="mx-2" max-width="30" ></v-img>
       </v-row>
        <!-- <div class="text-center desc font-weight-light">
            Use this form to request an NZeTA (New Zealand Electronic Travel Authority) and pay for an International Visitor Conservation and Tourism Levy (IVL).
        </div> -->
        <!-- <v-row class="requirements justify-center">
            <v-list dense class="req-list">
      <div class="text-center text-subtitle-1 mb-3">If you apply, make sure</div>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon :color="item.color">{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">{{item.text}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
    </v-list>
        </v-row>
        <v-row class="py-5 mx-5 justify-center">
            <v-alert
      color="#e9ecf0"
      dense
      icon="mdi-help-circle-outline"
      prominent
    >
    <strong>Groups / Family:</strong> Currently, there is no grouping on our forms, please submit your applications individually for each family or group member.
    </v-alert>
        </v-row> -->
      </div>
    </div>
      <div class="content">
        <section class="section">
    <div class="container">
        <div class="columns">
            <div class="column is-8 is-offset-2 pt-3">
                <horizontal-stepper v-if="render" :steps="demoSteps" @completed-step="completeStep" class="pt-5" :top-buttons="showButtons"
                                    @active-step="isStepActive" @stepper-finished="finish"
                >                     
                </horizontal-stepper>
            </div>
        </div>
    </div>
</section>
<Footer />
      </div>
      <!-- <v-card class="content elevation-0"> -->
    <!-- <v-card-title>
      Request your NZeTA
    </v-card-title>

    <v-card-subtitle>
      If your passport details are not correct you may not be able to travel.
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        color="orange lighten-2"
        text
      >
        Explore
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card> -->
    <v-dialog
      v-model="showResult"
      width="500"
      scrollable
    >

      <v-card>
        <v-card-title class="text-h5">
          Confirmation
          <v-spacer></v-spacer>
          <v-btn icon @click="showResult = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text style="max-height: 900px">
          <pre>
          {{JSON.stringify(formdata_final, null, 2)}}
          </pre>
          Are you sure you want to submit this application?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#00a34e"
            class="white--text elevation-0 rounded-0"
            @click="confirmApplication()"
            :loading="isSubmitting"
          >
          <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
    <LoadingScreen v-show="isSubmitting" />
  </div>
</template>

<script>
import StepOne from '../../components/Forms/StepOne.vue';
import StepTwo from '../../components/Forms/StepTwo.vue';
import StepThree from '../../components/Forms/StepThree.vue';
import StepFour from '../../components/Forms/StepFour.vue';
import StepFive from '../../components/Forms/StepFive.vue';
import StepSix from '../../components/Forms/StepSix.vue';
import StepSeven from '../../components/Forms/StepSeven.vue';
import StepEight from '../../components/Forms/StepEight.vue';
import Translation from '../../components/Utils/Translation.vue';
import Footer from '../../components/Footer/Footer.vue';
import LoadingScreen from '../../components/Utils/LoadingScreen2.vue';
export default {
  name: "Home",
  components: {
    Translation,
    Footer,
    LoadingScreen
  },
  data: () => ({
    demoSteps: [
        {
            icon: 'mdi-briefcase-account',
            name: 'first',
            title: 'Applicant Information',
            subtitle: 'Step One',
            component: StepOne,
            completed: false

        },
        {
            icon: 'mdi-card-account-details',
            name: 'second',
            title: 'Passport Information',
            subtitle: 'Step Two',
            component: StepTwo,
            completed: false
        },
        {
            icon: 'mdi-account-file-text',
            name: 'third',
            title: 'Personal Information',
            subtitle: 'Step Three',
            component: StepThree,
            completed: false
        },
        {
            icon: 'mdi-contacts',
            name: 'Fourth',
            title: 'Social Media Information',
            subtitle: 'Step Four',
            component: StepFour,
            completed: false
        },
        {
            icon: 'mdi-badge-account-horizontal',
            name: 'Fifth',
            title: 'Employment Information',
            subtitle: 'Step Five',
            component: StepFive,
            completed: false
        },
        {
            icon: 'mdi-airplane',
            name: 'Sixth',
            title: 'Travel Information',
            subtitle: 'Step Six',
            component: StepSix,
            completed: false
        },
        {
            icon: 'mdi-account-question',
            name: 'Seventh',
            title: 'Eligibility Information',
            subtitle: 'Step Seven',
            component: StepSeven,
            completed: false
        },
        {
            icon: 'mdi-text-box-search',
            name: 'Eighth',
            title: 'Review and Certification',
            subtitle: 'Step Eight',
            component: StepEight,
            completed: false
        },
    ],
    items: [
        { text: "You're visiting for a maximum of 90 days", icon: 'mdi-check-circle-outline', color: 'green' },
        { text: "You're travelling exclusively for business, transit or pleasure", icon: 'mdi-check-circle-outline', color: 'green' },
        { text: 'All questions must be answered in English using English-language characters only', icon: 'mdi-alert-outline', color: 'red' },
      ],
    showButtons: true,
    showResult: false,
    formdata_final: {},
    isSubmitting: false,
    cloudflare_ping: {},
    render: false,
  }),
  methods: {
      completeStep(payload) {
          this.demoSteps.forEach((step, index) => {
              if (step.name === payload.name) {
                // if(step.name == 'second'){
                //     this.uploadPhotoAndSave(index);
                // }
                // else{
                    this.saveApplication(index + 1, false);
                // }
              }
          })
      },
      isStepActive(payload) {
          this.demoSteps.forEach((step) => {
              if (step.name === payload.name) {
                  if(step.completed === true) {
                      step.completed = false;
                  }
              }
          })
      },
      finish(payload) {
          console.log("Finished", payload)
          this.formdata_final = this.finalizeData();
          // this.showResult = true;
          this.confirmApplication();
      },
      finalizeData(){
        let language = this.$store.state.language.substring(0,2);
          let data = this.$store.state.formdata;
          let applicant = data.applicant || {
            email: null,
            first_given_names: null,
            family_names: null,
            previous_names_bool: false,
            previous_names: [],
            dob_day: null,
            dob_month: null,
            dob_year: null,
            birth_country: null,
            birth_city: null,
          };
          let passport = data.passport || {
            country_of_citizenship: null,
            issuing_country: null,
            passport_number: null,
            passport_number_confirm: null,
            passport_issuance_day: null,
            passport_issuance_month: null,
            passport_issuance_year: null,
            passport_expiration_day: null,
            passport_expiration_month: null,
            passport_expiration_year: null,
            national_identification_number: null,
            personal_identification_number: null,
            gender: null,
            has_other_issuing_country_bool: null,
            has_other_issuing_country: [],
            has_other_citizenship_country_bool: null,
            has_other_citizenship_country: [],
            has_other_citizenship_country_before_bool: null,
            has_other_citizenship_country_before: [],
            did_not_acquire_citizenship_bool: data.did_not_acquire_citizenship_bool || null,
            renounced_citizenship_bool: null,
            have_not_lived_birth_country_in_5_years_bool: null,
            have_not_held_passport_in_past_5_years_bool: null,
            other_bool: null,
            other_text: null,
            has_ge_passid_bool: null,
            ge_passid: null,
          };
          let personal = data.personal || {
            address_line_1: null,
            address_line_2: null,
            apartment_number: null,
            city: null,
            state: null,
            country: null,
            telephone_type: null,
            phone_number: null,
            mother_family_names: null,
            mother_first_given_names: null,
            father_family_names: null,
            father_first_given_names: null
          };
          let pn = personal.phone_number
          personal.phone_number = pn ? pn.replace(/ /g, "") : null
          let social_media = data.social_media || {
            social_media_presence_bool: false,
            facebook_page_id: null,
            linkedin_profile_link: null,
            twitter_user_id: null,
            instagram_username: null,
            platform: null,
            social_media_identifier: null,
          };
          let employment = data.employment || {
            current_previous_employer_bool: false,
            job_title: null,
            employer_name: null,
            employer_address_line_1: null,
            employer_address_line_2: null,
            employer_city: null,
            employer_state: null,
            employer_country: null,
            employer_phone_number: null,
          };
          let epn = employment.employer_phone_number
          employment.employer_phone_number = epn ? epn.replace(/ /g, "") : null
          let travel = data.travel || {
            transit_bool: null,
            us_contact_unknown_bool: null,
            us_contact_name: null,
            us_contact_address_line_1: null,
            us_contact_address_line_2: null,
            us_contact_apartment_number: null,
            us_contact_city: null,
            us_contact_state: null,
            us_contact_phone_number: null,
            us_address_same_as_us_contact_bool: null,
            us_address_line_1: null,
            us_address_line_2: null,
            us_apartment_number: null,
            us_city: null,
            us_state: null,
            emergency_contact_family_names: null,
            emergency_contact_first_given_names: null,
            emergency_contact_email_address: null,
            emergency_contact_phone_number: null,
          };
          let ucpn = travel.us_contact_phone_number
          travel.us_contact_phone_number = ucpn ? ucpn.replace(/ /g, "") : null
          let ecpn = travel.emergency_contact_phone_number
          travel.emergency_contact_phone_number = ecpn ? ecpn.replace(/ /g, "") : null
          let eligibility = data.eligibility || {
            mental_disorder_bool: null,
            been_arrested_bool: null,
            drugs_bool: null,
            terrorist_bool: null,
            obtain_visa_bool: null,
            usa_employment_bool: null,
            rejected_visa_bool: null,
            stay_longer_bool: null,
            been_in_forbidden_countries_bool: null,
            been_in_cuba_bool: null,
          };
          let certification = data.certification || {
            agree_with_applicant_declaration_bool: null,
            agree_with_applicant_declaration_timestamp: null,
            agree_with_applicant_declaration_ip: null,
            agree_with_applicant_declaration_uag: null,
            agree_with_third_party_authorization_bool: null,
            agree_with_third_party_authorization_timestamp: null,
            agree_with_third_party_authorization_ip: null,
            agree_with_third_party_authorization_uag: null,
            signed_application_bool: null,
            signed_application_as: null,
            signed_application_timestamp: null,
            signed_application_ip: null,
            signed_application_uag: null,
          };
          let final_data = {
            token: this.$store.state.token,
            type: "USA-ESTA",
            lang: language && language != 'auto' ? language.toLowerCase() : 'en',
            aff_id: this.$store.state.aff_id ? this.$store.state.aff_id : "",
            aff_cid: this.$store.state.aff_cid ? this.$store.state.aff_cid : "",
            media_property: "usa-esta",
            ...applicant,
            ...passport,
            ...personal,
            ...social_media,
            ...employment,
            ...travel,
            ...eligibility,
            ...certification,
          }
          final_data = JSON.parse(JSON.stringify(final_data).replace(/"\s+|\s+"/g,'"'))
          return final_data;
      },
      confirmApplication(){
        this.isSubmitting = true;
        this.saveApplication(8, true);
      },
      saveApplication(step, completed){
        let lcs = this.$store.state.formdata.last_completed_step || 0;
        let other_data = {
            // signed_application_bool: completed == 1 ? true : false,
            // signed_application_timestamp: new Date(Date.now()),
            // signed_application_ip: this.cloudflare_ping.ip,
            // signed_application_uag: this.cloudflare_ping.uag,
            last_completed_step: step > lcs ? step : lcs,
            completed: completed
        }
        let formdata = this.finalizeData();
        formdata = {...formdata, ...other_data}
        // console.log(formdata)
        this.axiosCall(this.apiUrl2 + "/submit/", "POST", formdata)
            .then((res) => {
                if (res.data) {
                    if(completed){
                        // this.$router.push('/')
                        // this.$router.go(0);
                        window.open("https://ketoniks-eta.com/payment/" + this.$store.state.token + "/", "_self")
                        this.isSubmitting = false;
                        this.showResult = false;
                    }
                    this.demoSteps[step - 1].completed = true;
                    if(step == 1){
                      window._gs('event', 'Applicant Information');
                      window._gs('identify', {
                          id: formdata.token,
                          first_name: formdata.first_given_names,
                          last_name: formdata.family_names,
                          email: formdata.email,
                          username: formdata.token
                      });
                    }
                    else if(step == 2){
                      window._gs('event', 'Passport Information');
                    }
                    else if(step == 3){
                      window._gs('event', 'Personal Information');
                      window._gs('identify', {
                          id: formdata.token,
                          phone: formdata.phone_number,
                      });
                    }
                    else if(step == 4){
                      window._gs('event', 'Social Media Information');
                    }
                    else if(step == 5){
                      window._gs('event', 'Employment Information');
                    }
                    else if(step == 6){
                      window._gs('event', 'Travel Information');
                    }
                    else if(step == 7){
                      window._gs('event', 'Eligibility Information');
                    }
                    else if(step == 8){
                      window._gs('event', 'Review & Certification');
                    }
                }
            })
            .catch((error) => {
                this.isSubmitting = false;
                console.log(error);
            });
      },
      uploadPhotoAndSave(index){
        console.log(index)
        // let photo = this.$store.state.formdata.traveller.photo;
        // let photo_uploaded = this.$store.state.formdata.traveller.photo_url;
        // let api_length = this.apiUrl1.length;
        // if(photo){
        // let formdata = new FormData();
        // formdata.append('file', photo);
        // formdata.append('file_delete', photo_uploaded ? photo_uploaded.substring(api_length + 1, photo_uploaded.length) : '');
        // this.axiosCall(this.apiUrl1 + "/upload.php", "POST", formdata, {'Content-Type' : 'multipart/form-data'})
        // .then((res) => {
        //     if(res.data.image != ''){
        //         let data = this.$store.state.formdata;
        //         data.traveller.photo_url = this.apiUrl1 + '/' + res.data.image;
        //         this.$store.dispatch("setFormdata", data);
        //         this.saveApplication(index + 1, false);
        //     }
        //     else{
        //         console.log(res.data.message)
        //     }
        // }).catch((error) => {
        //     console.log(error);
        // });
        // }
        // else{
        //     this.saveApplication(index + 1, false);
        // }
    },
    getCloudflare(){
    this.axiosCall(this.apiUrl1 + "/cdn-cgi/trace", "GET")
          .then((res) => {
                let data = res.data.trim().split('\n').reduce(function(obj, pair) {
                pair = pair.split('=');
                return obj[pair[0]] = pair[1], obj;
                }, {});
                this.cloudflare_ping = data;
          })
          .catch((error) => {
            console.log(error);
          });
  },
  },
  mounted() {
    this.render = true;
    // this.getCloudflare();
    if(this.$store.state.formdata){
    let step = this.$store.state.formdata.last_completed_step || 0
    if(step < 8){
    let formdata = this.$store.state.formdata;
    let token = this.$store.state.token;
    this.demoSteps.forEach((item, index) => {
        if(index < parseInt(step)){
            item.completed = true;
            if((index + 1) == 1){
              window._gs('event', 'Applicant Information');
              window._gs('identify', {
                  id: token,
                  first_name: formdata.applicant.first_given_names,
                  last_name: formdata.applicant.family_names,
                  email: formdata.applicant.email,
                  username: token
              });
            }
            else if((index + 1) == 2){
              window._gs('event', 'Passport Information');
            }
            else if((index + 1) == 3){
              window._gs('event', 'Personal Information');
              window._gs('identify', {
                  id: token,
                  phone: formdata.personal.phone_number,
              });
            }
            else if((index + 1) == 4){
              window._gs('event', 'Social Media Information');
            }
            else if((index + 1) == 5){
              window._gs('event', 'Employment Information');
            }
            else if((index + 1) == 6){
              window._gs('event', 'Travel Information');
            }
            else if((index + 1) == 7){
              window._gs('event', 'Eligibility Information');
            }
            else if((index + 1) == 8){
              window._gs('event', 'Review & Certification');
            }
        }
    });
    }
    }
  },
};
</script>

<style scoped>
.outer-content {
  background-color: #fff !important;
}
.bg-content {
  /* background: url("../../assets/img/background.png"); */
   position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.inner-content{
    background-color: #fff !important;
    padding: 0 13%;
}
.content{
    margin: 0 10%;
}
.title{
    margin-top: 80px;
}
.desc {
    margin-top: 30px;
}
.requirements {
  margin-top: 20px;
}
.req-list{
  background-color: transparent !important;
}
@media only screen and (max-width: 950px) {
  .title{
    margin: 0;
    margin-top: 60px;
  }
  .content{
    margin: 0;
  }
  .requirements {
    margin: 0;
    margin-top: 20px;
  }
  .inner-content{
    padding: 0 1%;
    }
}
</style>
