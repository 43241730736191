<template>
  <v-dialog
    v-model="translate"
    width="400"
    persistent
    scrollable
    >
    <template v-slot:activator="{ on, attrs }">
        <!-- <v-chip v-bind="attrs" v-on="on" outlined color="#00a34e"><span class="notranslate">{{language_title}}</span></v-chip> -->
        <v-chip v-bind="attrs" v-on="on" outlined @click="selectLanguage()" id="language_btn"><span class="notranslate">
        <span v-if="triggered">
        <v-avatar left>
            <v-img :src="flags + lang_selected.title + '.png'"></v-img>
        </v-avatar>
        {{(lang_selected.title)}}
        </span>
        <span v-else>
            <v-progress-circular
                indeterminate
                :width="3"
                color="#00a34e"
                class="mr-1"
                size="20"
            ></v-progress-circular>
            language
        </span>
        </span>
        </v-chip>
      </template>
    <v-card>
        <v-card-title class="text-h5 grey lighten-3">
          Languages
          <v-spacer></v-spacer>
          <v-btn icon @click="hideModal()" id="language_btn_close"> <v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text style="max-height: 400px" class="pa-0 notranslate">
            <Translator :countries="countries" @on-country-click="hideModal()" />
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "Translation",
  data() {
    return {
    flags: 'https://cdn.jsdelivr.net/gh/lewis-kori/vue-google-translate/src/assets/images/flags/__',
    translate: false,
      countries: [
        {
            code: 'en|af',
            title: 'Afrikaans',
        },
        {
            code: 'en|sq',
            title: 'Albanian',
        },
        {
            code: 'en|ar',
            title: 'Arabic',
        },
        {
            code: 'en|hy',
            title: 'Armenian',
        },
        {
            code: 'en|az',
            title: 'Azerbaijani',
        },
        {
            code: 'en|eu',
            title: 'Basque',
        },
        {
            code: 'en|be',
            title: 'Belarusian',
        },
        {
            code: 'en|bg',
            title: 'Bulgarian',
        },
        {
            code: 'en|ca',
            title: 'Catalan',
        },
        {
            code: 'en|zh-CN',
            title: 'Chinese (Simplified)',
        },
        {
            code: 'en|zh-TW',
            title: 'Chinese (Traditional)',
        },
        {
            code: 'en|hr',
            title: 'Croatian',
        },
        {
            code: 'en|cs',
            title: 'Czech',
        },

        {
            code: 'en|da',
            title: 'Danish',
        },
        {
            code: 'en|nl',
            title: 'Dutch',
        },
        {
            code: 'en|en',
            title: 'English',
        },
        {
            code: 'en|et',
            title: 'Estonian',
        },
        {
            code: 'en|tl',
            title: 'Filipino',
        },
        {
            code: 'en|fi',
            title: 'Finnish',
        },
        {
            code: 'en|fr',
            title: 'French',
        },

        {
            code: 'en|de',
            title: 'German',
        },
        {
            code: 'en|el',
            title: 'Greek',
        },
        {
            code: 'en|hu',
            title: 'Hungarian',
        },
        {
            code: 'en|id',
            title: 'Indonesian',
        },
        {
            code: 'en|ga',
            title: 'Irish',
        },
        {
            code: 'en|it',
            title: 'Italian',
        },
        {
            code: 'en|ja',
            title: 'Japanese',
        },
        {
            code: 'en|ko',
            title: 'Korean',
        },
        {
            code: 'en|lt',
            title: 'Lithuanian',
        },
        {
            code: 'en|ms',
            title: 'Malay',
        },
        {
            code: 'en|no',
            title: 'Norwegian',
        },
        {
            code: 'en|pl',
            title: 'Polish',
        },
        {
            code: 'en|pt',
            title: 'Portuguese',
        },
        {
            code: 'en|ro',
            title: 'Romanian',
            flagIconUrl: 'https://cdn3.iconfinder.com/data/icons/world-flags-circled-vol-3/48/Romania-512.png',
        },
        {
            code: 'en|ru',
            title: 'Russian',
        },
        {
            code: 'en|es',
            title: 'Spanish',
        },
        {
            code: 'en|sv',
            title: 'Swedish',
        },
        {
            code: 'en|th',
            title: 'Thai',
        },
        {
            code: 'en|tr',
            title: 'Turkish',
        },
        {
            code: 'en|uk',
            title: 'Ukrainian',
        },
        ],
        language: 'en',
        lang_selected: 'English',
        triggered: false,
    };
  },
  watch: {
    lang_selected: {
        handler(){

        }, 
        deep: true
    }
  },
  mounted(){
    setTimeout(() => {
        document.getElementById("language_btn").click();
    }, 0);
  },
  methods: {
    hideModal() {
      this.translate = false;
      setTimeout(() => {
        let language = document.documentElement.lang;
        this.$store.dispatch("setLanguage", language);
        this.lang_selected = this.displayLanguage(language);
        window._gs('event', 'Switched Language to: ' + this.lang_selected.title);
      }, 500);

    },
    selectLanguage(){
        if(this.triggered == false){
        setTimeout(() => {
            this.translate = false;
        }, 0);
        setTimeout(() => {
            let code = document.documentElement.lang;
            if(this.$store.state.language && this.$store.state.language != code){
                document.documentElement.lang = this.$store.state.language;
                code = this.$store.state.language;
                window.doGTranslate('en|' + code);
                
            }
                let default_lang = {
                code: 'en|en',
                title: 'English',
                };
                this.lang_selected =  code != null && code != 'auto' ? this.countries.find((country) => country.code.substring(3,country.code.length) == code) : default_lang;
                this.$store.dispatch("setLanguage", code);
                this.triggered = true;
                window._gs('event', 'Switched Language to: ' + this.lang_selected.title);
        }, 1000);
        }
    },
    displayLanguage(code){
        let default_lang = {
            code: 'en|en',
            title: 'English',
        };
        return code != null && code != 'auto' ? this.countries.find((country) => country.code.substring(3,country.code.length) == code) : default_lang;
      }
  },
};
</script>