<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Contact Information</div></v-col>
    <v-col cols="12" class="py-0">
        <v-alert
        text
        type="info"
        dense
        >
        Please add your contact information below.
    </v-alert>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 1<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.address_line_1"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        outlined
        placeholder="Address Line 1"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 2</div>
        <v-text-field
        v-model="formdata.address_line_2"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        outlined
        placeholder="Address Line 2"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Apartment Number</div>
        <v-text-field
        v-model="formdata.apartment_number"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        outlined
        placeholder="Apartment Number"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">City<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.city"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        outlined
        placeholder="City"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">State/Province/Region<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.state"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        outlined
        placeholder="State/Province/Region"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.country"
            @change="changeCountry()"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="country"
            item-value="code"
            :items="countries"
            item-color="#00a34e"
            placeholder="Select Country"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Telephone type<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.telephone_type"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            :items="telephone_types"
            item-color="#00a34e"
            placeholder="Telephone Type"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country Code + Phone<span class="asterisk">*</span></div>
        <span class="phone-style">
        <vue-tel-input-vuetify
          :valid-characters-only="true"
          v-model="formdata.phone_number"
          label=""
          color="grey"
          :rules="[formRules.required, valid_pn || 'Invalid phone number']"
          height="45"
          outlined
          type="tel"
          placeholder="Enter your phone number"
          @input="onInputPN"
        />
        </span>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Parents</div></v-col>
    <v-col cols="12" class="py-0">
        <v-alert
        text
        type="info"
        dense
        >
        Please list your parents (biological, adoptive, step-parents or guardians) names in the boxes to the right. All applicants are required to fill out this section.
    </v-alert>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Mother — Family Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.mother_family_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Mother — First (Given) Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.mother_first_given_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Father — Family Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.father_family_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="6" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Father — First (Given) Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.father_first_given_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="reset()" outlined color="grey darken-2" class="mx-1 rounded-0 elevation-0">reset</v-btn>
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        address_line_1: null,
        address_line_2: null,
        apartment_number: null,
        city: null,
        state: null,
        country: null,
        telephone_type: null,
        phone_number: null,
        mother_family_names: null,
        mother_first_given_names: null,
        father_family_names: null,
        father_first_given_names: null
    },
    countries: [],
    telephone_types: ["Home", "Work", "Mobile", "Other"],
    valid_pn: false,
    formatted_pn: null,
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
      other_names: {
        handler(data) {
            this.formdata.other_names = JSON.parse(JSON.stringify(data))
        },
        deep: true
    },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        this.countries = this.$store.state.countries
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.personal || {}));
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            let data = JSON.parse(JSON.stringify(this.formdata))
            data.phone_number = this.formatted_pn;
            formdata.personal = data;
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        moreNames(choice){
        if(choice && this.other_names.length == 0){
            this.other_names.push({
                lastname: null,
                firstname: null
            })
        }
        else{
            this.other_names = [];
        }
        },
        addName(){
        this.other_names.push({
            lastname: null,
            firstname: null
        })
        },
        removeName(index){
            this.other_names.splice(index, 1);
            if(this.other_names.length == 0){
                this.formdata.more_names = '1';
            }
        },
        changeCountry(){
            let ctf = this.$store.state.country_to_phone[this.formdata.country]
            if(ctf){
                let prefix = ctf.split(':')
                this.formdata.phone_number = '+' + prefix[0];
            }
        },
        onInputPN(formattedNumber, { number, valid, country }) {
            console.log(formattedNumber, number, valid, country)
            this.valid_pn = valid
            this.formatted_pn = number.e164
        },
        reset(){
            this.formdata = {
                passport: {
                    nationality: null,
                    territory: null,
                    number: null,
                    expiry_date: null,
                },
                residency_questions: [],
                eligibility_questions: [],
            };
            this.getSeparatedQuestions();
            this.$refs.formRef.resetValidation()
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
</style>