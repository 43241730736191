<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row no-gutters>
    <v-col cols="12"><div class="text-bold text-sm-h4 mt-4 agreement-title-style">Applicant's Declaration</div></v-col>
    <v-col cols="12" class="agreement-text-style">
        <p>I declare that the information I have provided in this application is truthful, complete and accurate to the best of my knowledge.</p>
        <p>I understand that an approved travel authorization is NOT a visa. It does not meet the legal or regulatory requirements to serve in lieu of a United States visa when a visa is required under United States law. Individuals who possess a valid visa will still be able to travel to the United States on that visa for the purpose for which it was issued. Individuals traveling on valid visas are not required to apply for a travel authorization.</p>
        <p>I have read and understood the terms of the Visa Waiver Program. I understand that If my Electronic Travel Authorization is approved, this approval establishes that I am eligible to travel to the United States under the Visa Waiver Program but does not guarantee that I am admissible to the United States. Upon arrival in the United States, I will be inspected by a Customs and Border Protection officer at a port of entry who may determine that I am inadmissible under the Visa Waiver Program or for any reason under United States law.</p>
        <p>I understand that attempting to obtain an Electronic System for Travel Authorization (ESTA) under the US Visa Waiver Program by the willful misrepresentation of a material fact, or fraud, is an offense under INA 217, 8 U.S.C. 1187 and the CFR Title 8, Subchapter B, Part 217 and may result in the permanent refusal of a Travel Authorization, or adjudicated inadmissibility for entry into the USA as well as removal from the United States and its territories after entry has been granted based on such willful misrepresentations.</p>
    </v-col>
  </v-row>
  <v-row no-gutters>
    <v-col cols="12"><div class="text-bold text-sm-h4 agreement-title-style">Third Party Authorization</div></v-col>
    <v-col cols="12" class="agreement-text-style">
    <p>I understand that I am applying via usa-esta.eta.it.com, product of UNOBIT LLC; a third-party that is not affiliated with the United States government, that the third-party cannot guarantee application approval, that the third-party service is offered to help expedite the process, and that I have the option to apply directly with the pertinent government websites. I also understand that my purchase includes a processing and service fee to UNOBIT LLC, which includes the USD $21.00 non-refundable government fee, required by the Travel Promotion Act of 2009 (Section 9 of the United States Capitol Police Administrative Technical Corrections Act of 2009, Pub. L. No. 111-145).</p>
    <p>I confirm and authorize UNOBIT LLC to assist me, by using their processing service in completing and reviewing my application. I understand and acknowledge that I have not been offered legal advice and that this company or its agents and affiliates have never claimed to be qualified to offer legal advice under any circumstance. I also confirm that I am submitting my application and signing it myself.</p>
    <p>By obtaining services from the UNOBIT LLC website I have agreed to the following: Terms of Use | Terms of Service | Refund Policy | Privacy Policy | Disclaimer</p>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <v-checkbox @change="declarationSet()" v-model="formdata.agree_with_applicant_declaration_bool" :rules="[formRules.certify]" color="#00a34e" hide-details>
        <template v-slot:label>
          <strong class="agree-with">
            Agree with Applicant's Declaration.
          </strong>
        </template>
      </v-checkbox>
      <v-checkbox @change="authorizationSet()" v-model="formdata.agree_with_third_party_authorization_bool" :rules="[formRules.certify]" color="#00a34e" hide-details>
        <template v-slot:label>
          <strong class="agree-with">
             Agree with Third Party Authorization.
          </strong>
        </template>
      </v-checkbox>
    </v-col>
  </v-row>
  <v-row class="mt-10">
    <v-col cols="12" md="9" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Signature of Applicant<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.signed_application_as"
        @change="signedApplicationSet()"
        @keyup="signingApplication()"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        placeholder="Signature of Applicant"
        outlined
        persistent-hint
        hint="To sign, enter your name as it appears on your passport FNAME LNAME"
        ></v-text-field>
    </v-col>
  </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="reset()" outlined color="grey darken-2" class="mx-1 rounded-0 elevation-0">reset</v-btn>
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        agree_with_applicant_declaration_bool: null,
        agree_with_applicant_declaration_timestamp: null,
        agree_with_applicant_declaration_ip: null,
        agree_with_applicant_declaration_uag: null,
        agree_with_third_party_authorization_bool: null,
        agree_with_third_party_authorization_timestamp: null,
        agree_with_third_party_authorization_ip: null,
        agree_with_third_party_authorization_uag: null,
        signed_application_bool: null,
        signed_application_as: null,
        signed_application_timestamp: null,
        signed_application_ip: null,
        signed_application_uag: null,
    },
    cloudflare_ping: {},
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.certification || {}));
        this.formdata.signed_application_as = this.formdata.signed_application_as ? this.formdata.signed_application_as : (formdata.applicant.first_given_names + (formdata.applicant.family_names ? ' ' + formdata.applicant.family_names : '')).toUpperCase()
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            formdata.certification = JSON.parse(JSON.stringify(this.formdata));
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        declarationSet(){
            if(this.formdata.agree_with_applicant_declaration_bool){
                this.axiosCall(this.apiUrl1 + "/cdn-cgi/trace", "GET")
                .then((res) => {
                        let data = res.data.trim().split('\n').reduce(function(obj, pair) {
                        pair = pair.split('=');
                        return obj[pair[0]] = pair[1], obj;
                        }, {});
                        this.formdata.agree_with_applicant_declaration_timestamp = new Date(Date.now());
                        this.formdata.agree_with_applicant_declaration_ip = data.ip;
                        this.formdata.agree_with_applicant_declaration_uag = data.uag;
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            else{
                this.formdata.agree_with_applicant_declaration_timestamp = null;
                this.formdata.agree_with_applicant_declaration_ip = null;
                this.formdata.agree_with_applicant_declaration_uag = null;
            }
            console.log(this.formdata)
        },
        authorizationSet(){
            if(this.formdata.agree_with_third_party_authorization_bool){
                this.axiosCall(this.apiUrl1 + "/cdn-cgi/trace", "GET")
                .then((res) => {
                        let data = res.data.trim().split('\n').reduce(function(obj, pair) {
                        pair = pair.split('=');
                        return obj[pair[0]] = pair[1], obj;
                        }, {});
                        this.formdata.agree_with_third_party_authorization_timestamp = new Date(Date.now());
                        this.formdata.agree_with_third_party_authorization_ip = data.ip;
                        this.formdata.agree_with_third_party_authorization_uag = data.uag;
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            else{
                this.formdata.agree_with_third_party_authorization_timestamp = null;
                this.formdata.agree_with_third_party_authorization_ip = null;
                this.formdata.agree_with_third_party_authorization_uag = null;
            }
        },
        signingApplication(){
            if(this.formdata.signed_application_as != null && this.formdata.signed_application_as != ''){
                this.formdata.signed_application_bool = true;
            }
            else{
                this.formdata.signed_application_bool = false;
            }
        },
        signedApplicationSet(){
            if(this.formdata.signed_application_bool){
                this.axiosCall(this.apiUrl1 + "/cdn-cgi/trace", "GET")
                .then((res) => {
                        let data = res.data.trim().split('\n').reduce(function(obj, pair) {
                        pair = pair.split('=');
                        return obj[pair[0]] = pair[1], obj;
                        }, {});
                        this.formdata.signed_application_timestamp = new Date(Date.now());
                        this.formdata.signed_application_ip = data.ip;
                        this.formdata.signed_application_uag = data.uag;
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            else{
                this.formdata.signed_application_timestamp = null;
                this.formdata.signed_application_ip = null;
                this.formdata.signed_application_uag = null;
            }
        },
        getCloudflare(){
            this.axiosCall(this.apiUrl1 + "/cdn-cgi/trace", "GET")
                .then((res) => {
                        let data = res.data.trim().split('\n').reduce(function(obj, pair) {
                        pair = pair.split('=');
                        return obj[pair[0]] = pair[1], obj;
                        }, {});
                        this.cloudflare_ping = data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
.agree-with {
    font-size: 18px;
}
.agreement-title-style {
    font-size: 15px !important;
    font-weight: bolder !important;
    color: #8c98a4;
}
.agreement-text-style {
    text-align: justify;
    color: #8c98a4;
    font-size: 15px;
    line-height: normal;
}
.agreement-text-style p {
    margin-bottom: 8px;
}
</style>