<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Travel Information</div></v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Is your travel to the US occurring to transit to another country?</div>
        <v-radio-group
            v-model="formdata.transit_bool"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <div v-if="formdata.transit_bool == false">
    <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">U.S. Point Of Contact Information</div></v-col>
    <v-col cols="12" class="py-0">
        <v-switch
        v-model="formdata.us_contact_unknown_bool"
        inset
        label="Unknown"
        color="#00a34e"
        ></v-switch>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.us_contact_unknown_bool">
        <v-alert
        type="error"
        dense
        >
        Selecting Unknown US Contact may lower your chance of application success, please enter a person, or business hotel if possible.
    </v-alert>
    </v-col>
    <v-col cols="12" class="py-0">
        <v-alert
        text
        type="info"
        dense
        >
        Enter the person or business you will be visiting in the United States. This can be the address of your hotel or lodging. If you do not yet have your final travel plans, you may select 'Unknown' to skip these fields, but it is advised that you fill it in to raise the chances of a successful application.
    </v-alert>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Name</div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Name"
        outlined
        persistent-hint
        disabled
        hint="If you do not personally know anyone in the US, you may enter the name of the hotel, store, company, organization, or University you plan to visit during your trip."
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_name"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        placeholder="Name"
        outlined
        persistent-hint
        hint="If you do not personally know anyone in the US, you may enter the name of the hotel, store, company, organization, or University you plan to visit during your trip."
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 1<span class="asterisk">*</span></div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Address Line 1"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_address_line_1"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 1"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 2</div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Address Line 2"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_address_line_2"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 2"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Apartment Number</div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="Apartment Number"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_apartment_number"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Apartment Number"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">City<span class="asterisk">*</span></div>
        <v-text-field
        v-if="formdata.us_contact_unknown_bool"
        color="grey"
        height="45"
        placeholder="City"
        outlined
        disabled
        ></v-text-field>
        <v-text-field
        v-else
        v-model="formdata.us_contact_city"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="City"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">State/Province/Region<span class="asterisk">*</span></div>
        <v-autocomplete
            v-if="formdata.us_contact_unknown_bool"
            color="grey"
            height="45"
            outlined
            item-text="state"
            item-value="code"
            :items="states"
            item-color="#00a34e"
            placeholder="State/Province/Region"
            disabled
        ></v-autocomplete>
        <v-autocomplete
            v-else
            v-model="formdata.us_contact_state"
            @change="changeState()"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="state"
            item-value="code"
            :items="states"
            item-color="#00a34e"
            placeholder="State/Province/Region"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country Code + Phone<span class="asterisk">*</span></div>
        <span class="phone-style">
        <vue-tel-input-vuetify
          :valid-characters-only="true"
          v-model="formdata.us_contact_phone_number"
          label=""
          color="grey"
          :rules="[formRules.required, valid_ucpn || 'Invalid phone number']"
          height="45"
          outlined
          type="tel"
          placeholder="Enter your phone number"
          :onlyCountries="$store.state.state_to_phone"
          @input="onInputUCPN"
        />
        </span>
    </v-col>
    </v-row>
    <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Address While in the U.S.</div></v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Use the same address as above?</div>
        <v-radio-group
            v-model="formdata.us_address_same_as_us_contact_bool"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 1<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.us_address_line_1"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 1"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">Address Line 2</div>
        <v-text-field
        v-model="formdata.us_address_line_2"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Address Line 2"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">Apartment Number</div>
        <v-text-field
        v-model="formdata.us_apartment_number"
        color="grey"
        :rules="[formRules.englishAndNumberOnly]"
        height="45"
        placeholder="Apartment Number"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">City<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.us_city"
        color="grey"
        :rules="[formRules.required, formRules.englishAndNumberOnly]"
        height="45"
        placeholder="City"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0" v-if="formdata.us_address_same_as_us_contact_bool == false">
        <div class="px-0 pb-2 text-subtitle-1">State/Province/Region<span class="asterisk">*</span></div>
        <v-autocomplete
            v-model="formdata.us_state"
            color="grey"
            :rules="[formRules.required]"
            height="45"
            outlined
            item-text="state"
            item-value="code"
            :items="states"
            item-color="#00a34e"
            placeholder="State/Province/Region"
        ></v-autocomplete>
    </v-col>
    </v-row>
  </div>
    <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Emergency Contact Information in or out of the U.S.</div></v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Family Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.emergency_contact_family_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        placeholder="Family Name(s)"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">First (Given) Name(s)<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.emergency_contact_first_given_names"
        color="grey"
        :rules="[formRules.required, formRules.englishOnly]"
        height="45"
        placeholder="First (Given) Name(s)"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Email Address<span class="asterisk">*</span></div>
        <v-text-field
        v-model="formdata.emergency_contact_email_address"
        color="grey"
        :rules="[formRules.required, formRules.email]"
        height="45"
        placeholder="Email Address"
        outlined
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Country Code + Phone<span class="asterisk">*</span></div>
        <span class="phone-style">
        <vue-tel-input-vuetify
          :valid-characters-only="true"
          v-model="formdata.emergency_contact_phone_number"
          label=""
          color="grey"
          :rules="[formRules.required, valid_ecpn || 'Invalid phone number']"
          height="45"
          outlined
          type="tel"
          placeholder="Enter your phone number"
          @input="onInputECPN"
        />
        </span>
    </v-col>
    </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        transit_bool: null,
        us_contact_unknown_bool: null,
        us_contact_name: null,
        us_contact_address_line_1: null,
        us_contact_address_line_2: null,
        us_contact_apartment_number: null,
        us_contact_city: null,
        us_contact_state: null,
        us_contact_phone_number: null,
        us_address_same_as_us_contact_bool: null,
        us_address_line_1: null,
        us_address_line_2: null,
        us_apartment_number: null,
        us_city: null,
        us_state: null,
        emergency_contact_family_names: null,
        emergency_contact_first_given_names: null,
        emergency_contact_email_address: null,
        emergency_contact_phone_number: null,
    },
    states: [],
    valid_ucpn: false,
    valid_ecpn: false,
    formatted_ucpn: null,
    formatted_ecpn: null
    }),
    watch: {
    formdata: {
        handler () {
         this.$refs.formRef.resetValidation()
        this.edit();
        },
        deep: true,
      },
      other_names: {
        handler(data) {
            this.formdata.other_names = JSON.parse(JSON.stringify(data))
        },
        deep: true
    },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        this.years = range(currentYear, currentYear - 100, -1); 
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        this.states = this.$store.state.states
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.travel || {}));
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate()){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            let data = JSON.parse(JSON.stringify(this.formdata))
            data.us_contact_phone_number = this.formatted_ucpn;
            data.emergency_contact_phone_number = this.formatted_ecpn;
            formdata.travel = data;
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
        changeState(){
            if(this.formdata.us_contact_phone_number == null){
            this.formdata.us_contact_phone_number = '+1';
            }
        },
        onInputUCPN(formattedNumber, { number, valid, country }) {
            console.log(formattedNumber, number, valid, country)
            this.valid_ucpn = valid
            this.formatted_ucpn = number.e164
        },
        onInputECPN(formattedNumber, { number, valid, country }) {
            console.log(formattedNumber, number, valid, country)
            this.valid_ecpn = valid
            this.formatted_ecpn = number.e164
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
</style>