<template>
<div>
    <router-view v-show="render" />
    <loading-screen v-show="!render" />
</div>
</template>

<script>
export default {
  name: 'App',
  components: {
    "loading-screen": () => import("./components/Utils/LoadingScreen.vue"),
  },
  data: () => ({
    render: false,
    trigger_translate: false,
  }),
  mounted() {
    this.render = false;
    this.getToken();
    // document.onreadystatechange = () => {
    // if (document.readyState == "complete") {
    //     window.doGTranslate('en|' + this.$store.state.language);
    //   console.log('Page completed with image and files!')
    // }
//   }
  },
  methods: {
    getToken() {
        this.render = false;
        let urlParams = new URLSearchParams(window.location.search);
      if (this.$route.params.token && this.validateToken(this.$route.params.token)) {
        if(this.$route.params.token != this.$store.state.token){
          this.$store.dispatch("setToken", this.$route.params.token);
          this.trigger_translate = true
          // console.log("redirect here 1")
        }
        this.loadFormdata();
      }
      else if(urlParams.has('aff_id') && urlParams.has('cid')){
        this.$store.dispatch("setAffId", urlParams.get('aff_id'));
        this.$store.dispatch("setAffCId", urlParams.get('cid'));
        this.$store.dispatch("setFormdata", null);
        this.$store.dispatch("setLanguage", null);
        let token = this.generate_unique_identifier(2, "numbers") + "-" + this.generate_unique_identifier(2, "chars_upper") + "-" + this.generate_unique_identifier(8, "numbers");
        this.$store.dispatch("setToken", token);
        this.$router.push("/application/" + token + '/');
        this.$router.go()
      }
      else{
        if(this.$route.path == '/terms-of-use/' || this.$route.path == '/privacy-policy/'){
          this.render = true;
        }
        else{
        this.$store.dispatch("setFormdata", null);
        this.$store.dispatch("setLanguage", null);
        let token = this.generate_unique_identifier(2, "numbers") + "-" + this.generate_unique_identifier(2, "chars_upper") + "-" + this.generate_unique_identifier(8, "numbers");
        this.$store.dispatch("setToken", token);
        this.$router.push("/application/" + token + '/');
        this.$router.go()
        }
      }
    },
    generate_unique_identifier(length = 12, spec = null) {
      var chars = "";
      if(spec == null) {
          chars = "0123456789qwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnm";
      } else if(spec == "numbers") {
          chars = "0123456789";
      } else if(spec == "chars_upper") {
          chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      } else if(spec == "chars_lower") {
          chars = "abcdefghijklmnopqrstuvwxyz";
      } else if(spec == "mix_upper") {
          chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      } else if(spec == "mix_lower") {
          chars = "0123456789abcdefghijklmnopqrstuvwxyz";
      } else {
          chars = "0123456789qwertyuiopasdfghjklzxcvbnmqwertyuiopasdfghjklzxcvbnm";
      }
      var str = "";
      var size = chars.length;
      for (var i = 0; i < length; i++) {
          str += chars.charAt(Math.floor(Math.random() * size));
      }
      return str;
  },
  loadFormdata(){
    let token = this.$store.state.token;
    this.axiosCall(this.apiUrl2 + "/load/?media_property=usa-esta&token=" + token, "GET")
          .then((res) => {
            if(res.data.data) {
              let formdata = this.setupFormdata(JSON.parse(res.data.data));
              formdata.last_completed_step = res.data.last_completed_step;
              this.$store.dispatch("setFormdata", formdata);
              this.$store.dispatch("setLanguage", res.data.lang);
              if(res.data.completed == 1 && res.data.paid == 0 ){
                window.open("https://ketoniks-eta.com/payment/" + token + "/", "_self");
              }
              else if(res.data.completed == 1 && res.data.paid == 1 ){
                this.$router.push("/thank-you/" + token + '/');
              }
              else{
                this.$router.push("/application/" + token + '/');
              }
            // console.log("redirect here 3")
            }
            else{
              this.$store.dispatch("setFormdata", null);
              this.$store.dispatch("setLanguage", null);
              // console.log("redirect here 4")
            }
            if(this.trigger_translate){
                this.$router.go()
            }
            setTimeout(() => {
                this.render = true;
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            // console.log("redirect here 5")

          });
  },
  setupFormdata(data){
    let formdata = {};
    formdata.aff_id = data.aff_id;
    formdata.aff_cid = data.aff_cid;
    formdata.applicant = {
        email: data.email,
        first_given_names: data.first_given_names,
        family_names: data.family_names,
        previous_names_bool: data.previous_names_bool,
        previous_names: data.previous_names,
        dob_day: data.dob_day,
        dob_month: data.dob_month,
        dob_year: parseInt(data.dob_year),
        birth_country: data.birth_country,
        birth_city: data.birth_city,
    }
    formdata.passport = {
        country_of_citizenship: data.country_of_citizenship,
        issuing_country: data.issuing_country,
        passport_number: data.passport_number,
        passport_number_confirm: data.passport_number,
        passport_issuance_day: data.passport_issuance_day,
        passport_issuance_month: data.passport_expiration_month,
        passport_issuance_year: data.passport_expiration_year,
        passport_expiration_day: data.passport_expiration_day,
        passport_expiration_month: data.passport_expiration_month,
        passport_expiration_year: data.passport_expiration_year,
        national_identification_number: data.national_identification_number,
        personal_identification_number: data.personal_identification_number,
        gender: data.gender,
        has_other_issuing_country_bool: data.has_other_issuing_country_bool,
        has_other_issuing_country: data.has_other_issuing_country,
        has_other_citizenship_country_bool: data.has_other_citizenship_country_bool,
        has_other_citizenship_country: data.has_other_citizenship_country,
        has_other_citizenship_country_before_bool: data.has_other_citizenship_country_before_bool,
        has_other_citizenship_country_before: data.has_other_citizenship_country_before,
        did_not_acquire_citizenship_bool: data.did_not_acquire_citizenship_bool || null,
        renounced_citizenship_bool: data.renounced_citizenship_bool || null,
        have_not_lived_birth_country_in_5_years_bool: data.have_not_lived_birth_country_in_5_years_bool || null,
        have_not_held_passport_in_past_5_years_bool: data.have_not_held_passport_in_past_5_years_bool || null,
        other_bool: data.other_bool || null,
        other_text: data.other_text || null,
        has_ge_passid_bool: data.has_ge_passid_bool,
        ge_passid: data.ge_passid,
    }
    formdata.personal = {
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        apartment_number: data.apartment_number,
        city: data.city,
        state: data.state,
        country: data.country,
        telephone_type: data.telephone_type,
        phone_number: data.phone_number,
        mother_family_names: data.mother_family_names,
        mother_first_given_names: data.mother_family_names,
        father_family_names: data.father_family_names,
        father_first_given_names: data.father_first_given_names
    }
    formdata.social_media = {
        social_media_presence_bool: data.social_media_presence_bool,
        facebook_page_id: data.facebook_page_id,
        linkedin_profile_link: data.linkedin_profile_link,
        twitter_user_id: data.twitter_user_id,
        instagram_username: data.instagram_username,
        platform: data.platform,
        social_media_identifier: data.social_media_identifier,
    }
    formdata.employment = {
        current_previous_employer_bool: data.current_previous_employer_bool,
        job_title: data.job_title,
        employer_name: data.employer_name,
        employer_address_line_1: data.employer_address_line_1,
        employer_address_line_2: data.employer_address_line_2,
        employer_city: data.employer_city,
        employer_state: data.employer_state,
        employer_country: data.employer_country,
        employer_phone_number: data.employer_phone_number,
    }
    formdata.travel = {
        transit_bool: data.transit_bool,
        us_contact_unknown_bool: data.us_contact_unknown_bool,
        us_contact_name: data.us_contact_name,
        us_contact_address_line_1: data.us_contact_address_line_1,
        us_contact_address_line_2: data.us_contact_address_line_2,
        us_contact_apartment_number: data.us_contact_apartment_number,
        us_contact_city: data.us_contact_city,
        us_contact_state: data.us_contact_state,
        us_contact_phone_number: data.us_contact_phone_number,
        us_address_same_as_us_contact_bool: data.us_address_same_as_us_contact_bool,
        us_address_line_1: data.us_address_line_1,
        us_address_line_2: data.us_address_line_2,
        us_apartment_number: data.us_apartment_number,
        us_city: data.us_city,
        us_state: data.us_state,
        emergency_contact_family_names: data.emergency_contact_family_names,
        emergency_contact_first_given_names: data.emergency_contact_first_given_names,
        emergency_contact_email_address: data.emergency_contact_email_address,
        emergency_contact_phone_number: data.emergency_contact_phone_number,
    }
    formdata.eligibility = {
        mental_disorder_bool: data.mental_disorder_bool,
        been_arrested_bool: data.been_arrested_bool,
        drugs_bool: data.drugs_bool,
        terrorist_bool: data.terrorist_bool,
        obtain_visa_bool: data.obtain_visa_bool,
        usa_employment_bool: data.usa_employment_bool,
        rejected_visa_bool: data.rejected_visa_bool,
        stay_longer_bool: data.stay_longer_bool,
        been_in_forbidden_countries_bool: data.been_in_forbidden_countries_bool,
        been_in_cuba_bool: data.been_in_cuba_bool,
    }
    formdata.certification = {
        agree_with_applicant_declaration_bool: data.agree_with_applicant_declaration_bool,
        agree_with_applicant_declaration_timestamp: data.agree_with_applicant_declaration_timestamp,
        agree_with_applicant_declaration_ip: data.agree_with_applicant_declaration_ip,
        agree_with_applicant_declaration_uag: data.agree_with_applicant_declaration_uag,
        agree_with_third_party_authorization_bool: data.agree_with_third_party_authorization_bool,
        agree_with_third_party_authorization_timestamp: data.agree_with_third_party_authorization_timestamp,
        agree_with_third_party_authorization_ip: data.agree_with_third_party_authorization_ip,
        agree_with_third_party_authorization_uag: data.agree_with_third_party_authorization_uag,
        signed_application_bool: data.signed_application_bool,
        signed_application_as: data.signed_application_as,
        signed_application_timestamp: data.signed_application_timestamp,
        signed_application_ip: data.signed_application_ip,
        signed_application_uag: data.signed_application_uag,
    }
    console.log(formdata)
    return formdata;
  },
  },
};
</script>