import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store';
import OuterLayout from '../layouts/OuterLayout.vue';
import Application from '../views/Pages/Application.vue';
import ThankYou from '../views/Pages/ThankYou.vue';
import TermsOfUse from '../views/Pages/TermsOfUse.vue';
import PrivacyPolicy from '../views/Pages/PrivacyPolicy.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        meta: { RouteForTraveller: true },
        component: OuterLayout,
        children: [
                {
                path: '/application/:token/',
                alias: '/application/:aff_id:cid',
                component: Application
                },
                {
                path: '/thank-you/:token/',
                component: ThankYou
                },
                {
                path: '/terms-of-use/',
                component: TermsOfUse
                },
                {
                path: '/privacy-policy/',
                component: PrivacyPolicy
                },
            ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // var token = localStorage.getItem('token');
    if (to.matched.some(record => record.meta.RouteForTraveller)) {
        next()
    }
    // else {
    //     next({path: '/'})
    // }
})

export default router