<template>
<div class="pa-10 div-container">
    <v-form @submit.prevent ref="formRef">
  <v-row>
    <v-col cols="12"><div class="text-h5 text-bold text-sm-h4">Social Media Information</div></v-col>
    <v-col cols="12" class="py-0">
        <v-alert
        text
        type="info"
        dense
        >
        Please add your social media details below.
    </v-alert>
    </v-col>
    <v-col cols="12" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Do you have social media presence?</div>
        <v-radio-group
            v-model="formdata.social_media_presence_bool"
            :rules="[formRules.required3]"
            row
            class="mt-0"
            >
            <v-radio
                label="No"
                :value="false"
                color="#00a34e"
            ></v-radio>
            <v-radio
                label="Yes"
                :value="true"
                color="#00a34e"
            ></v-radio>
        </v-radio-group>
    </v-col>
    <v-col cols="12" class="py-0" v-if="formdata.social_media_presence_bool == true">
    <v-row>
    <v-col cols="12" v-if="no_social_medias" class="socmed-error">An answer to at least one social media is required.</v-col>
    <v-col cols="12" md="3" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Facebook Page ID</div>
        <v-text-field
        v-model="formdata.facebook_page_id"
        color="grey"
        height="45"
        outlined
        placeholder="Facebook Page ID"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="3" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">LinkedIn Profile Link</div>
        <v-text-field
        v-model="formdata.linkedin_profile_link"
        color="grey"
        height="45"
        outlined
        placeholder="LinkedIn Profile Link"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="3" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Twitter User ID</div>
        <v-text-field
        v-model="formdata.twitter_user_id"
        color="grey"
        height="45"
        outlined
        placeholder="Twitter User ID"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="3" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Instagram Username</div>
        <v-text-field
        v-model="formdata.instagram_username"
        color="grey"
        height="45"
        outlined
        placeholder="Instagram Username"
        ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Platform</div>
        <v-autocomplete
            v-model="formdata.platform"
            color="grey"
            height="45"
            outlined
            :items="platforms"
            item-color="#00a34e"
            placeholder="Select Platform"
        ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="8" class="py-0">
        <div class="px-0 pb-2 text-subtitle-1">Social Media Identifier</div>
        <v-text-field
        v-model="formdata.social_media_identifier"
        color="grey"
        height="45"
        outlined
        placeholder="Social Media Identifier"
        ></v-text-field>
    </v-col>
    </v-row>
    </v-col>
    <v-col cols="12" class="pt-3"><v-divider></v-divider></v-col>
  </v-row>
  <v-row class="justify-center mt-10" v-show="false">
  <v-btn @click="confirm()" id="confirm_btn" color="grey darken-2" class="mx-1 rounded-0 elevation-0 white--text">save Details</v-btn>
  </v-row>
  </v-form>
</div>
</template>
<script>
  export default {
    props: ['clickedNext', 'currentStep'],
    data: () => ({
    formdata: {
        social_media_presence_bool: false,
        facebook_page_id: null,
        linkedin_profile_link: null,
        twitter_user_id: null,
        instagram_username: null,
        platform: null,
        social_media_identifier: null,
    },
    no_social_medias: true,
    platforms: ["ASKfm", "Facebook", "Flickr", "GitHub", "Instagram", "JustPaste.it", "LinkedIn", "Tumblr", "Twitter", "Vine", "VKontakte (VK)", "Youtube", "Other"],
    }),
    watch: {
    formdata: {
        handler (data) {
         this.$refs.formRef.resetValidation()
        this.edit();
        if(data.facebook_page_id || data.linkedin_profile_link || data.twitter_user_id || data.instagram_username || data.social_media_identifier){
            this.no_social_medias = false;
        }
        else{
            this.no_social_medias = true;
        }
        },
        deep: true,
      },
    clickedNext(val) {
        console.log("clicked-next", val)

        const el = document.querySelector(".error--text");
        if(el){
        this.$vuetify.goTo(".error--text", { offset: 60 })
        }
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    currentStep(val) {
        console.log("current-step", val)
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
    },
    menu1(val) {
        val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    },
    created() {
    },
    mounted() {
        this.$refs.formRef.resetValidation()
        if(this.$store.state.formdata != null){
        let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata));
        this.formdata = JSON.parse(JSON.stringify(formdata.social_media || {}));
        setTimeout(() => {
            document.getElementById("confirm_btn").click();
        }, 0);
        const el = document.querySelector("#stepper-unique-div");
            el.scrollIntoView({
                behavior: 'smooth'
            })
            return;
        }
    },
    methods: {
        save (date) {
            this.$refs.menu1.save(date)
        },
        confirm(){
            if(this.$refs.formRef.validate() && (!this.no_social_medias || !this.formdata.social_media_presence_bool)){
            let formdata = JSON.parse(JSON.stringify(this.$store.state.formdata || {}));
            formdata.social_media = JSON.parse(JSON.stringify(this.formdata));
            this.$store.dispatch("setFormdata", formdata);
            this.$emit("can-continue", {value: true});
            }
            else {
                this.$emit("can-continue", {value: false});
                this.$emit('change-next', {nextBtnValue: false});
                // this.$refs.formRef.resetValidation()
            }
        },
        edit(){
            this.$emit("can-continue", {value: false});
            this.$emit('change-next', {nextBtnValue: false});
            setTimeout(() => {
            document.getElementById("confirm_btn").click();
            }, 0);
        },
    },
  }
</script>
<style scoped>
.asterisk{
    color: #ed527c;
    font-size: 20px;
    margin-left: 7px;
}
.other_names_style{
    background-color: #f2f2f2;
    border-radius: 4px;
}
.socmed-error {
    color: #ff5959 !important;
}
</style>